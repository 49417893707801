/* Spacings */
$SPACING-XL-MOBILE: 60px !default;
$SPACING-XL-TABLET: 90px !default;
$SPACING-XL-DESKTOP: 100px !default;

$SPACING-L-MOBILE: 45px !default;
$SPACING-L-TABLET: 60px !default;
$SPACING-L-DESKTOP: 80px !default;

$SPACING-M-MOBILE: 30px !default;
$SPACING-M-TABLET: 40px !default;
$SPACING-M-DESKTOP: 60px !default;

$SPACING-S-MOBILE: 20px !default;
$SPACING-S-TABLET: 24px !default;
$SPACING-S-DESKTOP: 40px !default;

$SPACING-XS-MOBILE: 10px !default;
$SPACING-XS-TABLET: 12px !default;
$SPACING-XS-DESKTOP: 20px !default;

$SPACING-XXS-MOBILE: 6px !default;
$SPACING-XXS-TABLET: 8px !default;
$SPACING-XXS-DESKTOP: 10px !default;

$SPACING-WRAPPER-MOBILE: 0 !default;
$SPACING-WRAPPER-TABLET: 48px !default;
$SPACING-WRAPPER-DESKTOP: 100px !default;

$SPACING-LAYOUT-MOBILE: 10px !default;
$SPACING-LAYOUT-TABLET: 12px !default;
$SPACING-LAYOUT-DESKTOP: 20px !default;
