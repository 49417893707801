ul,
ol {
    font-size: var(--font-size-p);
    font-weight: 300;
    line-height: 1.25;
}

li {
    font-size: var(--font-size-p);
}
