$header-image-animation-max-scale: 110% !default;
$header-image-headline-spacing: var(--spacing-m) !default;
$header-image-headline-color: $COLOR-WHITE !default;
$header-animation-duration: 5s !default;
$header-aspect-ratio: $RATIO-TWELVE-FIVE !default;

@keyframes header-scale-image {
    0% {
        scale: 100%;
    }

    100% {
        scale: $header-image-animation-max-scale;
    }

}

.c-header-image {
    position: relative;
    overflow: hidden;
    aspect-ratio: $header-aspect-ratio;
}

.c-header-image__headline {
    margin-top: $header-image-headline-spacing;
    color: $header-image-headline-color;
    pointer-events: none;
    user-select: none;
}

.c-header-image__image {
    animation: $header-animation-duration ease-out header-scale-image;
    animation-fill-mode: forwards;
    position: absolute;
    z-index: -1;
}
