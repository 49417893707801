$gallery-border: 2px solid gray !default;
$gallery-aspect-ratio: $RATIO-SIXTEEN-NINE !default;

.c-gallery {
    display: grid;
    margin: 0;
    padding: 0;
    width: 100%;
    gap: var(--spacing-xxs);
}

@for $i from 1 through 4 {
    .c-gallery--col-#{$i} {
        grid-template-columns: repeat($i, 1fr);

        @if $i > 2 {
            grid-template-columns: repeat(2, 1fr);
        }

        @include mq($from: mobile, $until: tabletportrait) {
            grid-template-columns: repeat($i, 1fr);

            @if $i > 3 {
                grid-template-columns: repeat(3, 1fr);
            }
        }

        @include mq($from: tabletportrait) {
            grid-template-columns: repeat($i, 1fr);
        }
    }
}

.c-gallery__item {
    @include hidden-list-style;

    margin: 0;
    border: $gallery-border;
    padding: 0;
    cursor: pointer;
    aspect-ratio: $gallery-aspect-ratio;
}
