$search-header-color: $COLOR-WHITE !default;
$search-header-spacing: 12px !default;
$search-header-border-color: $COLOR-GREY !default;
$search-header-border-radius: 50px !default;
$search-header-border-width: 2px !default;
$search-header-box-shadow-size: 0 0 20px 10px !default;
$search-header-input-spacing: 2px 10px !default;
$search-header-input-spacing-right: $SPACING-XS-TABLET !default;
$search-header-input-font-size: $inuit-font-size-h6-sm !default;
$search-header-input-width: 22ch !default;
$search-header-input-max-width: 250px !default;
$search-header-animation-duration: 0.5s !default;
$search-header-icon-left-field-right-spacing: $SPACING-XS-MOBILE !default;
$search-header-icon-left-field-right-input-spacing: $SPACING-XXS-MOBILE !default;
$search-header-icon-left-field-right-input-width: 17ch !default;

.c-search-header {
    --btn-color: #{$search-header-border-color};

    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    padding: $search-header-spacing;
    background-color: $search-header-color;
    box-shadow: $search-header-box-shadow-size $search-header-color;

    @include mq($from: tabletportrait) {
        position: absolute;
        right: 0;
    }
}

.c-search-header--icon-left-field-right {
    flex-direction: unset;
    justify-content: flex-start;
    padding: $search-header-icon-left-field-right-spacing 0;

    .is-visible {
        .c-search-header__form {
            flex-direction: row-reverse;
        }

        .c-search-header__form-input {
            margin: 0 $search-header-icon-left-field-right-input-spacing;
            width: $search-header-icon-left-field-right-input-width;
        }
    }
}

.c-search-header__form {
    display: flex;
    align-items: center;

    .c-btn {
        display: none;
    }

    .is-visible & {
        .c-btn {
            display: flex;
        }
    }

    &.is-hidden .c-search-header__form-input {
        pointer-events: none;
    }
}

.c-search-header__form .c-search-header__form-input {
    display: none;
    margin: 0 $search-header-input-spacing-right;
    border-radius: $search-header-border-radius;
    border: $search-header-border-width solid $search-header-border-color;
    width: $search-header-input-width;
    max-width: 0;
    padding: $search-header-input-spacing;
    font-size: $search-header-input-font-size;

    .is-visible & {
        display: block;
        animation-name: fade-in;
        animation-duration: $search-header-animation-duration;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
    }

    .is-hidden & {
        display: block;
        animation-name: fade-out;
        animation-duration: $search-header-animation-duration;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
    }
}

@keyframes fade-in {
    0% {
        max-width: 0;
        color: transparent;
    }

    100% {
        max-width: $search-header-input-max-width;
    }
}
@keyframes fade-out {
    0% {
        max-width: $search-header-input-max-width;
    }

    80% {
        opacity: 1;
    }

    99% {
        max-width: 0;
    }

    100% {
        opacity: 0;
    }
}
