$tab-group-spacing: 0 0 $SPACING-XS-TABLET !default;
$tab-bg-color: $COLOR-GREY-LIGHT !default;
$tab-bg-color-active: $COLOR-YELLOW !default;
$tab-bg-color-secondary: $COLOR-BEIGE !default;
$tab-color: $COLOR-BLACK !default;
$tab-font-weight: 600 !default;
$tab-spacing: var(--spacing-xxs) !default;
$tab-spacing-large: var(--spacing-xs) !default;
$tab-accordion-spacing: $tab-spacing calc($tab-spacing * 2) $tab-spacing var(--spacing-m) !default;
$tab-accordion-spacing-large: $tab-spacing calc($tab-spacing * 2) $tab-spacing 48px !default;

.c-tabs--accordion {
    .c-tabs__content {
        &.is-hidden {
            display: block;

            .c-tabs__button {
                background-color: $tab-bg-color;
            }

            .c-tabs__inner {
                display: none;

                @include mq($from: tabletportrait) {
                    display: block;
                }
            }
        }
    }

    .c-tabs__group {
        display: none;

        @include mq($from: tabletportrait) {
            display: block;
        }
    }
}

.c-tabs__group {
    @include typo-s;
    @include hidden-list-style;

    display: flex;
    margin: $tab-group-spacing;
    font-weight: $tab-font-weight;
}

.c-tabs__group--small {
    gap: $tab-spacing-large;
}

.c-tabs__item {
    transition: none;
}

.c-tabs__item,
.c-tabs__item:focus,
.c-tabs__item:hover {
    justify-content: start;
    border: none;
    border-radius: 0;
    width: 100%;
    padding: $tab-spacing calc($tab-spacing * 2);
    background-color: $tab-bg-color;
}

.c-tabs__item:focus,
.c-tabs__item:hover,
.c-tabs__item[aria-selected='true'] {
    background-color: $tab-bg-color-active;
    color: $tab-color;
}

.c-tabs__content {
    &.is-hidden {
        display: none;
    }
}

.c-tabs--secondary {
    .c-tabs__item:focus,
    .c-tabs__item:hover,
    .c-tabs__item[aria-selected='true'] {
        background-color: $tab-bg-color-secondary;
        color: $tab-color;
    }
}

.c-tabs__button {
    display: block;
    border: none;
    margin-bottom: $tab-spacing;
    width: 100%;
    padding: $tab-accordion-spacing;
    background-color: $tab-bg-color-active;
    font-weight: $tab-font-weight;
    text-align: start;
    color: $tab-color;

    @include mq($from: tabletportrait) {
        display: none;
    }

    @include mq($from: mobile) {
        padding: $tab-accordion-spacing-large;
    }

    @include mq($from: tabletportrait) {
        &.is-hidden {
            display: none;
        }
    }
}
