$content-list-spacing: var(--spacing-xs) !default;
$content-list-title-spacing: 2px !default;

.c-content-list__title,
.c-content-list__description {
    margin-bottom: $content-list-spacing;
    word-break: break-word;

    @include mq($until: mobile) {
        line-height: 1.25;
    }
}

.c-content-list__title {
    @include typo-s;

    --font-weight: 600;

    margin-top: $content-list-title-spacing;

    @include mq($until: mobile) {
        --font-size-h5: var(--font-size-h6);
    }
}
