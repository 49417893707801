$ce-spacing: $SPACING-S-MOBILE !default;

@include mq($from: mobile) {
    .c-ce-textpic.c-ce-left.c-ce-intext.c-ce-nowrap {
        display: flex;
    }

    .c-ce-textpic.c-ce-left.c-ce-intext.c-ce-nowrap .c-ce-bodytext {
        flex: 1;
    }

    .c-ce-textpic.c-ce-left.c-ce-intext:not(.c-ce-nowrap) {
        display: flow-root;
    }

    .c-ce-textpic.c-ce-left.c-ce-intext:not(.c-ce-nowrap) .c-ce-gallery {
        float: left;
    }

    .c-ce-textpic.c-ce-left.c-ce-intext .c-ce-gallery {
        margin-right: $ce-spacing;
    }

    .c-ce-textpic.c-ce-right.c-ce-intext.c-ce-nowrap {
        display: flex;
    }

    .c-ce-textpic.c-ce-right.c-ce-intext.c-ce-nowrap .c-ce-gallery {
        order: 2;
    }

    .c-ce-textpic.c-ce-right.c-ce-intext.c-ce-nowrap .c-ce-bodytext {
        flex: 1;
    }

    .c-ce-textpic.c-ce-right.c-ce-intext:not(.c-ce-nowrap) {
        display: flow-root;
    }

    .c-ce-textpic.c-ce-right.c-ce-intext:not(.c-ce-nowrap) .c-ce-gallery {
        float: right;
    }

    .c-ce-textpic.c-ce-right.c-ce-intext .c-ce-gallery {
        margin-left: $ce-spacing;
    }

    .c-ce-textpic.c-ce-left:not(.c-ce-intext) .c-ce-gallery {
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
    }

    .c-ce-textpic.c-ce-right:not(.c-ce-intext) .c-ce-gallery {
        display: flex;
        justify-content: end;
        flex-wrap: wrap;
    }

    .c-ce-textpic.c-ce-center .c-ce-gallery,
    .c-ce-image.c-ce-center .c-ce-gallery {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.c-ce-gallery .c-ce-row {
    display: flex;
    margin-bottom: $ce-spacing;
}

.c-ce-textpic .c-ce-gallery .c-ce-row .c-ce-column {
    flex: none;
}

.c-ce-gallery .c-ce-row .c-ce-column:not(:last-child) {
    margin-right: $ce-spacing;
}

.c-ce-bodytext .o-wrapper {
    padding: 0;
}

@include mq($until: tabletportrait) {
    .c-ce-gallery .c-ce-row {
        flex-direction: column;
    }

    .c-ce-gallery .c-ce-row .c-ce-column:not(:last-child) {
        margin-right: initial;
        margin-bottom: $ce-spacing;
    }
}
