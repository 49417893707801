@mixin color-scheme-vars($prefix, $list-scheme-properties, $color-scheme) {
    @each $name, $values in $color-scheme {
        #{$prefix}--#{$name} {
            @each $property in $list-scheme-properties {
                $value: map_get($values, $property);

                @if $value {
                    --#{$property}: #{$value};
                }
            }
        }
    }
}

@mixin hidden-list-style {
    list-style-type: none;

    &::before {
        content: '';
        position: absolute;
    }
}

@mixin focus-visible {
    &:focus-visible {
        outline: 3px solid var(--focus-visible-color);
        outline-offset: 3px;
    }
}

/* text overflow $lines: truncates text at a specific number of lines. text-overflow(2) // truncate text after two lines */
@mixin text-overflow($lines: 1) {
    display: block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @if $lines == 0 {
        white-space: revert;
        -webkit-box-orient: revert;
        -webkit-line-clamp: revert;

    } @else if $lines > 1 {
        @supports (-webkit-line-clamp: 2) {
            display: -webkit-box;
            white-space: initial;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: $lines;
        }
    }
}

@mixin has-n-children($n) {
    @if $n == 1 {
        &:only-child {
            @content;
        }
    } @else {
        &:first-child:nth-last-child(#{$n}),
        &:first-child:nth-last-child(#{$n}) ~ & {
            @content;
        }
    }
}
