$partner-spacing: var(--spacing-s) !default;
$partner-size: 1020px !default;
$partner-layout-item-spacing: var(--spacing-layout) !default;
$partner-link-size: 140px !default;
$partner-link-size-large: 240px !default;

.c-partner {
    margin: auto;
    max-width: $partner-size;
    padding: $partner-spacing 0;
    text-align: center;

    .c-partner__layout {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: $partner-layout-item-spacing;
        margin-top: $partner-spacing;
    }

    .c-partner__link {
        display: block;
        overflow: hidden;
        width: $partner-link-size;
        height: $partner-link-size;

        @include mq($from: tabletportrait) {
            width: $partner-link-size-large;
            height: $partner-link-size-large;
        }

        &:hover,
        &:focus-visible {
            .c-partner__logo {
                filter: grayscale(0);
            }
        }
    }

    .c-partner__logo {
        filter: grayscale(1);
    }
}
