$input-border-radius: 15px !default;
$input-text-padding: 2px 10px !default;
$input-font-size: 0.9rem !default;
$input-font-weight: 600 !default;

$input-checkbox-and-radio-background-color: $COLOR-WHITE !default;
$input-checkbox-and-radio-margin: 0 var(--spacing-xxs) 0 0 !default;

$input-radio-size: .5rem !default;
$input-radio-padding: 7px !default;
$input-radio-border-radius: 50% !default;

$input-checkbox-size: 1.15em !default;
$input-checkbox-padding: 10px !default;
$input-checkbox-border-radius: 5px !default;
$input-checkbox-transform: translateY(-0.075em) !default;

$input-mark-transition: 120ms transform ease-in-out !default;
$input-mark-box-shadow: inset 1em 1em $COLOR-RED !default;
$input-mark-size: 0.65em !default;

$input-checkbox-clip-path: polygon(15% 45%, 0 65%, 50% 100%, 100% 15%, 80% 0%, 40% 60%) !default;
$input-radio-clip-path: circle(40%) !default;

$select-padding: 4px 10px !default;
$select-width: 180px !default;

fieldset {
    margin: 0;
}

input,
button,
textarea,
select {
    font: inherit;
}

input[type="text"],
input[type="email"],
input[type="date"],
input[type="search"],
input[type="password"],
textarea,
select {
    border: none;
    border-radius: $input-border-radius;
    width: 100%;
    padding: $input-text-padding;
    font-size: $input-font-size;
}

label,
legend {
    @include typo-xxs;

    text-transform: uppercase;
    font-size: $input-font-size;
    font-weight: $input-font-weight;
}

input[type="checkbox"],
input[type="radio"] {
    appearance: none;
    display: grid;
    place-content: center;
    margin: $input-checkbox-and-radio-margin;
    background-color: $input-checkbox-and-radio-background-color;
    font-weight: $input-font-weight;
    text-transform: uppercase;
    color: currentcolor;
}

input[type="radio"] {
    border-radius: $input-radio-border-radius;
    width: $input-radio-size;
    height: $input-radio-size;
    padding: $input-radio-padding;
}

input[type="checkbox"] {
    border-radius: $input-checkbox-border-radius;
    transform: $input-checkbox-transform;
    width: $input-checkbox-size;
    height: $input-checkbox-size;
    padding: $input-checkbox-padding;
    font: inherit;
    color: currentcolor;
}

input[type="checkbox"]::before,
input[type="radio"]::before {
    content: "";
    transform: scale(0);
    transition: $input-mark-transition;
    width: $input-mark-size;
    height: $input-mark-size;
    box-shadow: $input-mark-box-shadow;
}

input[type="checkbox"]::before {
    transform-origin: bottom left;
    clip-path: $input-checkbox-clip-path;
}

input[type="checkbox"]:checked::before {
    transform: scale(1);
}

input[type="radio"]::before {
    transform-origin: center center;
    clip-path: $input-radio-clip-path;
}

input[type="radio"]:checked::before {
    transform: scale(1);
}

select {
    appearance: none;
    min-width: $select-width;
    width: 100%;
}
