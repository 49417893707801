:root {
    @include mq($until: mobile) {
        --font-size-h1: #{$inuit-font-size-h1-xs};
        --font-size-h2: #{$inuit-font-size-h2-xs};
        --font-size-h3: #{$inuit-font-size-h3-xs};
        --font-size-h4: #{$inuit-font-size-h4-xs};
        --font-size-h5: #{$inuit-font-size-h5-xs};
        --font-size-h6: #{$inuit-font-size-h6-xs};
        --font-size-p: #{$font-size-p-xs};
        --font-size-button: #{$font-size-button-xs};
    }

    @include mq($from: mobile, $until: tabletlandscape) {
        --font-size-h1: #{$inuit-font-size-h1-sm};
        --font-size-h2: #{$inuit-font-size-h2-sm};
        --font-size-h3: #{$inuit-font-size-h3-sm};
        --font-size-h4: #{$inuit-font-size-h4-sm};
        --font-size-h5: #{$inuit-font-size-h5-sm};
        --font-size-h6: #{$inuit-font-size-h6-sm};
        --font-size-p: #{$font-size-p-sm};
        --font-size-button: #{$font-size-button-sm};
    }

    @include mq($from: tabletlandscape) {
        --font-size-h1: #{$inuit-font-size-h1};
        --font-size-h2: #{$inuit-font-size-h2};
        --font-size-h3: #{$inuit-font-size-h3};
        --font-size-h4: #{$inuit-font-size-h4};
        --font-size-h5: #{$inuit-font-size-h5};
        --font-size-h6: #{$inuit-font-size-h6};
        --font-size-p: #{$font-size-p};
        --font-size-button: #{$font-size-button};
    }
}

h1 {
    @include typo-xxl;
}

h2 {
    @include typo-xl;
}

h3 {
    @include typo-l;
}

h4 {
    @include typo-m;
}

h5 {
    @include typo-s;
}

p {
    @include typo-xs;
}

h6 {
    @include typo-xxs;
}
