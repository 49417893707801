$text-decoration-thickness: 2px !default;
$icon-font-family: icons !default;
$icon-pdf: "\f10b" + " " !default;

a {
    text-decoration: var(--link-text-decoration, underline);
    color: var(--link-color);

    &:hover {
        color: var(--link-color-hover);
        text-shadow: 0 0 1px currentcolor;
        text-decoration-thickness: $text-decoration-thickness;
    }

    &:focus {
        background-color: var(--link-background-color);
        text-decoration-thickness: $text-decoration-thickness;
    }

    &:focus-visible {
        background-color: var(--link-background-color);
        text-decoration-thickness: $text-decoration-thickness;
    }
}

a[href$=".pdf"] {
    &::before {
        content: $icon-pdf;
        font-family: $icon-font-family;
    }
}
