// overwriting inuit variables

$mq-breakpoints: (
    mobile: 431px,
    tabletportrait: 845px,
    tabletlandscape: 1281px,
    desktop: 1921px,
    fullhd: 2561px,
    wide: 2560px,
);

$inuit-font-size-h1: 60px !default;
$inuit-font-size-h1-sm: 42px !default;
$inuit-font-size-h1-xs: 26px !default;

$inuit-font-size-h2: 42px !default;
$inuit-font-size-h2-sm: 32px !default;
$inuit-font-size-h2-xs: 22px !default;

$inuit-font-size-h3: 32px !default;
$inuit-font-size-h3-sm: 26px !default;
$inuit-font-size-h3-xs: 18px !default;

$inuit-font-size-h4: 22px !default;
$inuit-font-size-h4-sm: 20px !default;
$inuit-font-size-h4-xs: 16px !default;

$inuit-font-size-h5: 18px !default;
$inuit-font-size-h5-sm: 18px !default;
$inuit-font-size-h5-xs: 16px !default;

$inuit-font-size-h6: 16px !default;
$inuit-font-size-h6-sm: 16px !default;
$inuit-font-size-h6-xs: 14px !default;

$font-size-p: 18px !default;
$font-size-p-sm: 18px !default;
$font-size-p-xs: 16px !default;

$font-size-button: 16px !default;
$font-size-button-sm: 16px !default;
$font-size-button-xs: 16px !default;

//$inuit-widths-breakpoint-separator: \-;
$inuit-wrapper-width: 1840px;

$inuit-fractions: 1 2 3 4 5 6 7 8 9 10 11 12 !default;
