@use "sass:math";

$podcast-spacing: var(--spacing-m) !default;
$podcast-content-size: math.percentage(math.div(2, 3)) !default;
$podcast-media-size: math.percentage(math.div(1, 3)) !default;
$podcast-body-max-characters: 70ch !default;
$podcast-body-spacing: var(--spacing-s) !default;
$podcast-media-spacing: var(--spacing-s) !default;
$podcast-aspect-ratio: $RATIO-SIXTEEN-NINE !default;

.c-podcast {
    padding: $podcast-spacing;

    @include mq($from: mobile) {
        padding: $podcast-spacing 0;
    }

    @include mq($from: tabletportrait) {
        display: flex;
    }
}

.c-podcast__header {
    --headline-horizontal-spacing: 0;

    @include typo-m;
}

.c-podcast__body {
    max-width: $podcast-body-max-characters;
    margin: $podcast-body-spacing 0;
}

.c-podcast__content {
    @include mq($from: tabletportrait) {
        width: $podcast-content-size;
    }
}

.c-podcast__media {
    margin: $podcast-media-spacing 0 0 0;
    height: auto;

    @include mq($from: tabletportrait) {
        margin: auto 0 auto $podcast-media-spacing;
        width: $podcast-media-size;
    }
}

.c-podcast__link {
    --link-text-decoration: none;

    display: inline-flex;
}

.c-podcast__image {
    aspect-ratio: $podcast-aspect-ratio;
}
