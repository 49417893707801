$header-logo-width: 230px !default;
$header-logo-spacing: var(--spacing-m) !default;
$header-logo-spacing-vertical: var(--spacing-s) !default;

.c-header {
    display: block;
}

.c-header__container {
    display: flex;
}

.c-header__left {
    flex-basis: $header-logo-width;
    flex-shrink: 0;
}

.c-header__right {
    position: relative;

    @include mq($until: tabletportrait) {
        display: none;
    }

    @include mq($from: tabletportrait) {
        display: flex;
        align-items: center;
        width: 100%;
    }

    @include mq($from: tabletportrait, $until: tabletlandscape) {
        justify-content: flex-end;
    }
}

.c-header__logo {
    display: block;
}

.c-header__link-text {
    @include inuit-hidden-visually;
}

.c-header__logo-img {
    margin: $header-logo-spacing;
    max-width: $header-logo-width;
    height: auto;

    @include mq($from: mobile) {
        margin: $header-logo-spacing-vertical 0;
    }
}
