$form-spacing: var(--spacing-s) !default;
$form-error-border: 2px solid $COLOR-RED !default;
$form-btns-spacing: var(--spacing-xxs) !default;
$form-btn-spacing: $SPACING-XXS-MOBILE !default;
$form-image-size: 20px !default;
$form-font-weight: 300 !default;
$form-item-spacing: $SPACING-S-MOBILE !default;
$form-icon-font: icons !default;
$form-icon-color: $COLOR-RED !default;

$form-select-content: "\f102" !default;
$form-select-right: 10px !default;
$form-select-top: 7px !default;
$form-select-rotate: 90deg !default;
$form-select-border: 1px solid $COLOR-GREY !default;

$form-datepicker-content: "\f105" !default;
$form-datepicker-right: 10px !default;
$form-datepicker-top: 5px !default;
$form-datepicker-bg-color: $COLOR-WHITE !default;
$form-datepicker-spacing: 1px 3px !default;

.c-form {
    padding: $form-spacing 0;

    .c-form__error,
    .f3-form-error {
        border: $form-error-border;
    }
}

.c-form__title {
    @include typo-xxs;

    --font-spacing-bottom: var(--spacing-xs);
    --font-weight: 600;
    --font-text-transform: uppercase;
}

.c-form__item {
    display: flex;
    flex-direction: column;
    margin-bottom: $form-item-spacing;
}

.c-form__radio,
.c-form__checkbox {
    .c-form__label {
        display: flex;
    }
}

.c-form__select {
    position: relative;

    &::after {
        content: $form-select-content;
        position: absolute;
        top: $form-select-top;
        right: $form-select-right;
        display: block;
        rotate: $form-select-rotate;
        pointer-events: none;
        line-height: 1;
        font-family: $form-icon-font;
        color: $form-icon-color;
    }
}

.c-form__select--border {
    select {
        border: $form-select-border;
    }
}

.c-form__datepicker {
    position: relative;

    input {
        width: 100%;
        text-transform: uppercase;
    }

    &::after {
        content: $form-datepicker-content;
        position: absolute;
        top: $form-datepicker-top;
        right: $form-datepicker-right;
        display: block;
        pointer-events: none;
        padding: $form-datepicker-spacing;
        background-color: $form-datepicker-bg-color;
        line-height: 1;
        font-family: $form-icon-font;
        color: $form-icon-color;
    }
}

.c-form__buttons {
    display: flex;
    gap: $form-btns-spacing;
    margin-bottom: $form-item-spacing;

    @include mq($from: tabletlandscape) {
        justify-content: space-between;
    }
}

.c-form__image {
    margin-right: $form-btn-spacing;
    width: $form-image-size;
}

.c-form__link {
    border: none;
    margin-bottom: 0;
    background-color: transparent;
    cursor: pointer;
    font-size: initial;
    text-decoration: underline;
    text-transform: uppercase;

    &:hover {
        text-shadow: 0 0 1px currentcolor;
        text-decoration-thickness: $text-decoration-thickness;
    }
}

.c-form__container {
    margin-bottom: $form-spacing;

    .c-form__checkbox-label {
        font-weight: $form-font-weight;
        text-transform: none;
    }
}
