$banner-spacing: var(--spacing-layout) !default;
$banner-aspect-ratio-eight-one: $RATIO-EIGHT-ONE !default;
$banner-aspect-ratio-six-one: $RATIO-SIX-ONE !default;
$banner-aspect-ratio-four-one: $RATIO-FOUR-ONE !default;
$banner-aspect-ratio-two-one: $RATIO-TWO-ONE !default;

:root {
    @include mq($until: tabletportrait) {
        .c-banner--six-one {
            .c-banner__image {
                aspect-ratio: $banner-aspect-ratio-four-one;
            }
        }

        .splide:not(.splide--sticky) .c-banner--eight-one {
            .c-banner__image {
                aspect-ratio: $banner-aspect-ratio-six-one;
            }
        }
    }
}

.c-banner {
    position: relative;

    &:hover {
        text-shadow: 0 0 0 transparent;
    }

    .splide--sticky & {
        margin: 0;
    }
}

.c-banner--two-one {
    .c-banner__image {
        aspect-ratio: $banner-aspect-ratio-two-one;
    }
}

.c-banner--six-one {
    .c-banner__image {
        aspect-ratio: $banner-aspect-ratio-six-one;
    }
}

.c-banner--eight-one {
    .c-banner__image {
        aspect-ratio: $banner-aspect-ratio-eight-one;
    }
}
