$breadcrumb-spacing: var(--spacing-m) !default;
$breadcrumb-spacing-small: var(--spacing-s) !default;
$breadcrumb-spacing-large: $SPACING-XL-DESKTOP !default;
$breadcrumb-color: $COLOR-BLACK !default;
$breadcrumb-link-spacing: $SPACING-XXS-MOBILE !default;
$breadcrumb-font-size: $inuit-font-size-h6-xs !default;

.c-breadcrumb {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 $breadcrumb-spacing-small;

    @include mq($until: mobile) {
        margin: 0 $breadcrumb-spacing-large $breadcrumb-spacing-small $breadcrumb-spacing;
    }
}

.c-breadcrumb__item {
    @include hidden-list-style;

    padding-right: $breadcrumb-link-spacing;
    font-size: $breadcrumb-font-size;

    &:first-child {
        a {
            padding-left: 0;
        }
    }

    a {
        --link-text-decoration: none;
        --link-color: $breadcrumb-color;

        padding-right: $breadcrumb-link-spacing;
        color: var(--link-color);

        &:hover {
            text-shadow: 0 0 1px var(--link-color);
        }
    }
}
