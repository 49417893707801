$news-search-link-color: $COLOR-BLACK !default;
$news-search-link-spacing: var(--spacing-s) !default;
$news-search-form-spacing: var(--spacing-s) !default;
$news-search-form-button-spacing: 5px !default;
$news-search-teaser-content-height: 110px !default;
$news-search-teaser-content-height-large: 145px !default;
$news-search-teaser-content-spacing: var(--spacing-s) !default;

$news-search-more-content-height: 108px !default;
$news-search-more-content-spacing: var(--spacing-xs) var(--spacing-s) !default;

.c-news-search {
    .c-teaser__content {
        height: $news-search-teaser-content-height;
        padding: $news-search-teaser-content-spacing;

        @include mq($from: tabletlandscape) {
            height: $news-search-teaser-content-height-large;
        }
    }
}

.c-news-search-form__button {
    margin-top: $news-search-form-button-spacing;
}

.c-news-search-form__link {
    --link-color: #{$news-search-link-color};
    --link-color-hover: #{$news-search-link-color};

    display: block;
    margin-bottom: $news-search-link-spacing;
    text-transform: uppercase;
    font-weight: bold;
}

.c-news-search-more {
    .c-teaser__content {
        padding: $news-search-more-content-spacing;

        @include mq($from: tabletlandscape) {
            height: $news-search-more-content-height;
        }
    }
}

.c-news-search-form__wrapper {
    @include mq($until: mobile) {
        margin-left: $news-search-form-spacing;
    }
}
