h1, h2, h3, h4, h5, h6 {
    @include mq($until: mobile) {
        margin-left: var(--headline-horizontal-spacing, var(--spacing-m));
        margin-right: var(--headline-horizontal-spacing, var(--spacing-m));
    }
}

strong {
    font-weight: bold;
}

small {
    font-size: $inuit-font-size-h6-xs;
    font-weight: bold;
    text-transform: uppercase;
}

p {
    .o-wrapper--content & {
        margin-bottom: var(--spacing-s);
    }
}

address {
    font-style: normal;
}

blockquote {
    --font-spacing-bottom: var(--spacing-xs);

    @include typo-xs;

    font-style: italic;
}
