$multisport-table-spacing: var(--spacing-m) !default;
$multisport-table-headline-spacing: 16px !default;
$multisport-table-headline-spacing-small: 8px !default;
$multisport-table-headline-font-size: 12px !default;
$multisport-table-cell-bg-color: $COLOR-WHITE !default;
$multisport-table-cell-spacing: 8px !default;
$multisport-table-cell-spacing-small: 4px !default;
$multisport-input-bg-color: $COLOR-GREY-LIGHT !default;
$multisport-input-result-bg-color: $COLOR-YELLOW !default;
$multisport-input-result-color: $COLOR-BLACK !default;
$multisport-buttons-gap: 16px !default;
$multisport-buttons-spacing: var(--spacing-s) 0 !default;

.c-multisport {
    .o-wrapper {
        @include mq($until: mobile) {
            padding: 0;
        }
    }

    table {
        margin-bottom: $multisport-table-spacing;
        text-align: center;
    }

    th {
        text-align: center;
        padding: $multisport-table-headline-spacing-small;
        font-size: $multisport-table-headline-font-size;

        @include mq($from: mobile) {
            padding: $multisport-table-headline-spacing;
            font-size: inherit;
        }
    }

    tbody {
        tr {
            &:nth-child(even) {
                background-color: $multisport-table-cell-bg-color;
            }

            &:first-child td {
                padding-top: $multisport-table-headline-spacing-small;

                @include mq($from: mobile) {
                    padding-top: $multisport-table-headline-spacing;
                }
            }
        }

        td {
            padding: $multisport-table-cell-spacing-small;

            @include mq($from: mobile) {
                padding: $multisport-table-cell-spacing;
            }

            &:last-child {
                padding-right: $multisport-table-cell-spacing;

                @include mq($from: mobile) {
                    padding-right: 0;
                }
            }
        }

        input {
            background-color: $multisport-input-bg-color;
            border-radius: inherit;
            margin: 0;
            width: 100%;
            padding: $multisport-table-headline-spacing-small;
            text-transform: unset;
            text-align: center;

            @include mq($from: mobile) {
                padding: $multisport-table-headline-spacing;
            }
        }
    }

    .c-multisport__result {
        text-align: right;
    }

    .c-multisport__input {
        background-color: $multisport-input-result-bg-color;
        opacity: 1;
        font-weight: bold;
        color: $multisport-input-result-color;
        -webkit-text-fill-color: currentcolor;
    }
}

.c-multisport__buttons {
    display: flex;
    gap: $multisport-buttons-gap;
    padding: $multisport-buttons-spacing;
}
