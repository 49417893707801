$pagination-gap: 4px !default;
$pagination-gap-large: $SPACING-XS-TABLET !default;
$pagination-spacing: $SPACING-XS-TABLET !default;
$pagination-link-color: $COLOR-RED !default;
$pagination-link-active-color: $COLOR-BLACK !default;

.c-pagination {
    display: flex;
    flex-wrap: wrap;
    gap: $pagination-gap;
    justify-content: center;
    margin: 0;
    list-style: none;
}

.c-pagination__number {
    display: inline-flex;
    gap: $pagination-gap;

    a {
        display: inline-block;
        width: fit-content;
        text-decoration: none;
    }

    &.is-active {
        --link-color: $pagination-link-active-color;
        --link-color-hover: $pagination-link-active-color;

        &::before {
            content: "|";
            color: $pagination-link-active-color;
        }

        &::after {
            content: "|";
            color: $pagination-link-active-color;
        }

        + a::before {
            content: "";
        }

        + .c-pagination__number::before {
            display: none;
        }
    }

    &::before {
        content: "|";
        color: $pagination-link-color;
    }

    &:hover::after {
        text-shadow: none;
        text-decoration-thickness: unset;
    }

    &:first-child::before {
        content: "";
    }

    &:last-child::after {
        content: "";
    }
}
