$copyright-spacing: var(--spacing-xs) !default;
$copyright-spacing-left: var(--spacing-m) !default;
$copyright-spacing-left-small: var(--spacing-wrapper) !default;
$copyright-bg-color: rgba(0, 0, 0, 30%) !default;

.c-copyright {
    @include typo-xxs;

    position: absolute;
    bottom: 0;
    left: 0;
    margin: 0;
    padding: $copyright-spacing;
    background-color: $copyright-bg-color;

    &:empty {
        display: none;
    }
}
