:root {
    --color: #{$COLOR-BLACK};
    --background-color: #{$COLOR-TRANSPARENT};
    --focus-visible-color: #{$COLOR-GREEN-LIGHT};
    --link-color: #{$COLOR-RED};
    --link-color-hover: #{$COLOR-RED};
    --link-background-color: #{$COLOR-TRANSPARENT};
    --btn-color: #{$COLOR-BLACK};
    --btn-bg-color: #{$COLOR-TRANSPARENT};
    --btn-color-hover: #{$COLOR-WHITE};
    --btn-bg-color-hover: #{$COLOR-BLACK};
    --btn-color-focus: #{$COLOR-WHITE};
    --btn-bg-color-focus: #{$COLOR-BLACK};
    --btn-color-disabled: #{rgba($COLOR-BLACK, 0.5)};
    --btn-bg-color-disabled: #{$COLOR-TRANSPARENT};
}

/* Schemes */
@include color-scheme-vars('.scheme', $theme-scheme-vars, $theme-color-schemes);

[class^='scheme--'],
[class*=' scheme--'] {
    @each $property in $theme-css-properties {
        #{$property}: var(--#{$property});
    }
}
