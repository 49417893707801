$choice-spacing: var(--spacing-xs) !default;
$choice-spacing-large: var(--spacing-s) !default;
$choice-bg-color: $COLOR-GREY-LIGHT !default;
$choice-label-font-weight: 300 !default;

.c-choice {
    .c-accordion__header {
        display: unset;
    }
}

.c-choice__item {
    border-bottom: 0;
}

.c-choice__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.c-choice__body {
    padding: $choice-spacing-large 0;
    background-color: $choice-bg-color;
}

.c-choice__radio {
    display: flex;
    align-items: center;
    margin-bottom: $choice-spacing;
    font-weight: $choice-label-font-weight;

    &:last-child {
        margin-bottom: 0;
    }
}
