@mixin typo-xxl {
    margin-bottom: var(--font-spacing-bottom, var(--spacing-xs));
    font-size: var(--font-size-h1, $inuit-font-size-h1-xs);
    line-height: 1.25;
    font-weight: var(--font-weight, 600);
    text-transform: var(--font-text-transform, uppercase);
}

@mixin typo-xl {
    margin-bottom: var(--font-spacing-bottom, var(--spacing-xs));
    font-size: var(--font-size-h2, $inuit-font-size-h2-xs);
    line-height: 1.25;
    font-weight: var(--font-weight, 600);
    text-transform: var(--font-text-transform, uppercase);
}

@mixin typo-l {
    margin-bottom: var(--font-spacing-bottom, var(--spacing-xs));
    font-size: var(--font-size-h3, $inuit-font-size-h3-xs);
    line-height: 1.25;
    font-weight: var(--font-weight, 300);
    text-transform: var(--font-text-transform, none);
}

@mixin typo-m {
    margin-bottom: var(--font-spacing-bottom, var(--spacing-xs));
    font-size: var(--font-size-h4, $inuit-font-size-h4-xs);
    line-height: 1.25;
    font-weight: var(--font-weight, 600);
    text-transform: var(--font-text-transform, uppercase);
}

@mixin typo-s {
    margin-bottom: var(--font-spacing-bottom, var(--spacing-xxs));
    font-size: var(--font-size-h5, $inuit-font-size-h5-xs);
    line-height: 1.25;
    font-weight: var(--font-weight, 300);
    text-transform: var(--font-text-transform, uppercase);
}

@mixin typo-xs {
    margin-bottom: var(--font-spacing-bottom, var(--spacing-xxs));
    font-size: var(--font-size-p, $font-size-p-xs);
    line-height: 1.25;
    font-weight: var(--font-weight, 300);
    text-transform: var(--font-text-transform, none);
}

@mixin typo-xxs {
    margin-bottom: var(--font-spacing-bottom, var(--spacing-xxs));
    font-size: var(--font-size-h6, $inuit-font-size-h6-xs);
    line-height: 1.25;
    font-weight: var(--font-weight, 300);
    text-transform: var(--font-text-transform, none);
}
