img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

iframe,
video {
    width: 100%;
    height: auto;
    aspect-ratio: $RATIO-SIXTEEN-NINE;
}
