/* Example:
<button class="c-btn">
    <span class="c-btn__text">Ergebnis finden</span>
    <span class="c-btn__icon"><i class="c-icon c-icon--search"></i></span>
</button>
*/

$btn-spacing: 0 15px !default;
$btn-spacing-tablet: 0 20px !default;
$btn-spacing-desktop: 0 25px !default;
$btn-size: 44px !default;
$btn-size-desktop: 48px !default;
$btn-icon-font: icons !default;
$btn-icon-spacing: 10px !default;
$btn-icon-spacing-left: var(--spacing-xxs) !default;
$btn-icon-size: var(--font-size-button) !default;
$btn-border-width: 2px !default;
$btn-border-typ: solid !default;
$btn-border-radius: 50px !default;
$btn-line-height: 1 !default;
$btn-font-weight: 600 !default;
$btn-rte-spacing: var(--spacing-xxs) !default;
$btn-rte-icon-font-size: 12px !default;
$btn-rte-icon-arrow: '\f101' !default;
$btn-rte-icon-pdf: '\f10b' !default;

:root {
    @include mq($until: mobile) {
        --btn-border-padding: #{$btn-spacing};
        --btn-size: #{$btn-size};
    }

    @include mq($from: mobile, $until: tabletlandscape) {
        --btn-border-padding: #{$btn-spacing-tablet};
        --btn-size: #{$btn-size-desktop};
    }

    @include mq($from: tabletlandscape) {
        --btn-border-padding: #{$btn-spacing-desktop};
        --btn-size: #{$btn-size-desktop};
    }
}

.c-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border: $btn-border-width $btn-border-typ var(--btn-color);
    border-radius: $btn-border-radius;
    margin: 0;
    padding: var(--btn-border-padding);
    height: var(--btn-size);
    background-color: var(--btn-bg-color);
    font-size: var(--font-size-button);
    line-height: $btn-line-height;
    font-weight: $btn-font-weight;
    text-transform: uppercase;
    color: var(--btn-color);

    &:is(a) {
        text-decoration: none;
    }

    &:hover {
        background-color: var(--btn-bg-color-hover);
        color: var(--btn-color-hover);
    }

    &:not(.c-btn--focus-disabled):focus {
        background-color: var(--btn-bg-color-focus);
        color: var(--btn-color-focus);
    }

    &[disabled],
    &.c-btn--disabled {
        border-color: var(--btn-color-disabled);
        background-color: var(--btn-bg-color-disabled);
        cursor: default;
        color: var(--btn-color-disabled);
    }
}

.c-btn__icon {
    .c-btn:not(.c-btn--only-icon) & {
        padding-left: $btn-icon-spacing-left;
    }

    .c-icon {
        display: flex;
        height: $btn-icon-size;
        width: $btn-icon-size;
        font-size: $btn-icon-size;
    }
}

.c-btn--inline {
    display: inline-flex;
}

.c-btn--fit-content {
    width: fit-content;
}

.c-btn--only-icon {
    --btn-border-padding: #{$btn-icon-spacing};

    border-color: var(--btn-only-icon-border-color, transparent);
    min-width: var(--btn-size);
}

.c-btn__text--hidden-visually {
    @include inuit-hidden-visually;
}

.c-btn--rte {
    display: inline-flex;
    margin-bottom: $btn-rte-spacing;
    margin-right: $btn-rte-spacing;

    &:hover {
        text-shadow: none;
    }

    &.c-btn__icon--arrow::after,
    &.c-btn__icon--pdf::before {
        font-family: $btn-icon-font;
        line-height: $btn-line-height;
        font-size: $btn-rte-icon-font-size;
    }

    &.c-btn__icon--arrow::after {
        content: $btn-rte-icon-arrow;
        padding-left: $btn-icon-spacing;
    }

    &.c-btn__icon--pdf::before {
        content: $btn-rte-icon-pdf;
        padding-right: $btn-icon-spacing;
    }
}
