$association-label-spacing: $SPACING-XXS-MOBILE !default;
$association-headline-spacing: var(--spacing-s) 0 0 !default;
$association-subline-spacing: var(--spacing-s) !default;
$association-map-color: $COLOR-GREY-LIGHT !default;
$association-state-focus-color: $COLOR-GREEN-LIGHT !default;
$association-state-stroke-color: $COLOR-WHITE !default;
$association-state-hover-color: $COLOR-YELLOW !default;
$association-name-color: $COLOR-BLACK !default;
$association-name-font-size: 10px !default;

.c-association__label {
    @include typo-m;

    display: block;
    margin-bottom: $association-label-spacing;
}

.c-association__headline {
    @include typo-m;

    margin: $association-headline-spacing;

    @include mq($from: tabletportrait) {
        margin: 0;
    }
}

.c-association__subline {
    @include typo-s;

    --font-weight: 700;

    margin-bottom: $association-subline-spacing;
}

.c-association__map {
    fill: $association-map-color;
}

.c-association__group {
    &:focus,
    &:focus-visible {
        outline: none;
    }

    &:not(.is-active):focus,
    &:not(.is-active):focus-visible {
        .c-association__state {
            fill: $association-state-focus-color;
        }
    }
}

.c-association__state {
    stroke: $association-state-stroke-color;
    stroke-linejoin: round;
    cursor: pointer;

    .is-active &,
    &:hover {
        fill: $association-state-hover-color;

        + .c-association__name {
            display: block;
        }
    }
}

.c-association__name {
    display: none;
    fill: $association-name-color;
    font-size: $association-name-font-size;
    pointer-events: none;
}
