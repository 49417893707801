$teaser-block-gap: var(--spacing-xs) !default;
$teaser-block-spacing: var(--spacing-m) !default;
$teaser-block-font-size: $font-size-p !default;
$teaser-block-item-bg-color: $COLOR-WHITE !default;
$teaser-block-item-color: $COLOR-RED !default;
$teaser-block-item-min-height: 260px !default;
$teaser-block-content-margin: var(--spacing-s) var(--spacing-m) !default;
$teaser-block-content-margin--desktop: var(--spacing-xs) var(--spacing-s) !default;

.c-teaser-block {
    display: grid;
    grid-template-columns: 1fr;
    gap: $teaser-block-gap;
    padding: $teaser-block-spacing;
    font-size: $teaser-block-font-size;

    @include mq($from: mobile, $until: tabletlandscape) {
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: 1fr;
        padding: $teaser-block-spacing 0;
    }

    @include mq($from: tabletlandscape) {
        grid-template-columns: repeat(8, 1fr);
        padding: $teaser-block-spacing 0;
    }
}

.c-teaser-block__item {
    grid-column: span 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: auto;
    min-height: $teaser-block-item-min-height;
    max-height: fit-content;
    background-color: $teaser-block-item-bg-color;

    @include mq($from: mobile, $until: tabletlandscape) {
        @include has-n-children(1) {
            grid-column: 2 / span 2;
        }

        @include has-n-children(3) {
            &:last-child {
                grid-column: 2 / span 2;
            }
        }
    }

    @include mq($from: tabletlandscape) {
        @include has-n-children(1) {
            grid-column: 4 / span 2;
        }

        @include has-n-children(2) {
            &:first-child {
                grid-column: 3 / span 2;
            }
        }

        @include has-n-children(3) {
            &:first-child {
                grid-column: 2 / span 2;
            }
        }
    }
}

.c-teaser-block__content {
    margin: $teaser-block-content-margin;

    @include mq($from: tabletlandscape) {
        margin: $teaser-block-content-margin--desktop;
    }

    :only-child {
        margin-bottom: 0;
    }
}

.c-teaser-block__headline {
    @include typo-m;

    --headline-horizontal-spacing: 0;
}

.c-teaser-block__media {
    width: 100%;
    height: 100%;
}

.c-teaser-block__link {
    color: $teaser-block-item-color;

    &:hover {
        color: $teaser-block-item-color;
    }
}
