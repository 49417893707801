$live-event-bar-spacing: var(--spacing-xxs) !default;
$live-event-bar-horizontal-spacing: var(--spacing-m) !default;

.c-live-event-bar {
    text-decoration: none;
}

.c-live-event-bar__content {
    display: flex;

    @include mq($until: mobile) {
        flex-direction: column;
        justify-content: flex-start;
        padding: 0 $live-event-bar-horizontal-spacing;
    }

    @include mq($from: mobile) {
        flex-direction: row;
        justify-content: space-between;
    }
}

.c-live-event-bar__title {
    margin-top: $live-event-bar-spacing;

    @include typo-s;
    @include mq($from: mobile) {
        flex-grow: 2;
    }
}

.c-live-event-bar__link {
    @include typo-s;
    @include mq($from: mobile) {
        flex-grow: 1;
        margin-top: $live-event-bar-spacing;
        text-align: end;
    }
}
