$media-box-spacing: var(--spacing-layout) !default;
$media-box-link-color: $COLOR-WHITE !default;
$media-box-link-bg-color: $COLOR-BLACK !default;
$media-box-link-spacing: var(--spacing-xxs) var(--spacing-xs) !default;
$media-box-text-spacing: var(--spacing-xs) 0 !default;
$media-box-splide-icon-color: $COLOR-BLACK !default;
$media-box-aspect-ratio: $RATIO-SIXTEEN-NINE !default;
$media-box-icon-size: 40px !default;
$media-box-icon-shadow: 0 0 14px $COLOR-GREY !default;
$media-box-icon-color: $COLOR-WHITE !default;

.c-media-box {
    position: relative;

    @include mq($until: tabletportrait) {
        margin-bottom: $media-box-spacing;
    }
}

.c-media-box__link {
    --link-text-decoration: none;
}

.c-media-box__headline {
    --link-color: #{$media-box-link-color};
    --link-color-hover: #{$media-box-link-color};
    --font-spacing-bottom: 0;

    @include typo-xs;

    display: block;
    width: 100%;
    padding: $media-box-link-spacing;
    background-color: $media-box-link-bg-color;
    text-transform: uppercase;
    color: $media-box-link-color;

    &:focus {
        background-color: $media-box-link-bg-color;
    }
}

.c-media-box__content {
    position: relative;

    .c-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        translate: -50% -50%;
        font-size: $media-box-icon-size;
        text-shadow: $media-box-icon-shadow;
        color: $media-box-icon-color;
    }
}

.c-media-box__image {
    aspect-ratio: $media-box-aspect-ratio;
}

.c-media-box__text {
    margin: $media-box-text-spacing;
}

.c-media-box__icon {
    --splide-icon-color: $media-box-splide-icon-color;
}
