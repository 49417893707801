/* Override inuitcss wrapper */

.o-wrapper {
    padding-right: var(--spacing-wrapper);
    padding-left: var(--spacing-wrapper);
}

.o-wrapper--content {
    @include mq($until: mobile) {
        --spacing-wrapper: var(--spacing-m);
        --headline-horizontal-spacing: 0;
    }
}
