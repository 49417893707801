$social-list-width: 140px !default;
$social-btn-spacing: 5px !default;
$social-btn-size: 44px !default;
$social-btn-icon-spacing: 10px !default;
$social-btn-icon-twitter: "\f10f" !default;
$social-btn-icon-twitter-font-size: 18px !default;
$social-btn-border-radius: 50px !default;
$social-btn-border-width: 2px !default;
$social-btn-bg-color: $COLOR-BLACK !default;
$social-btn-color: $COLOR-YELLOW !default;
$social-btn-font: icons !default;

.c-socials {
    display: flex;
    align-items: center;

    .c-socials__text {
        text-transform: uppercase;
        font-weight: bold;
    }

    .shariff ul {
        justify-content: space-evenly;
        width: $social-list-width;
    }

    // overwrite plugin stylings
    .shariff-button {
        min-width: unset !important;
        max-width: unset !important;
        height: $social-btn-size;
        width: $social-btn-size !important;
        flex-grow: unset !important;
        margin-bottom: 0 !important;

        .fa-twitter::before {
            content: $social-btn-icon-twitter;
            font-family: $social-btn-font;
            font-size: $social-btn-icon-twitter-font-size;
        }
    }

    [role="button"] {
        display: flex;
        justify-content: center;
        align-items: center;
        border: $social-btn-border-width solid $social-btn-bg-color;
        border-radius: $social-btn-border-radius;
        padding: $social-btn-spacing;
        height: $social-btn-size;
        width: $social-btn-size;
        background-color: $social-btn-bg-color !important;
        color: $social-btn-color;
    }

    .share_text {
        display: none !important;
    }
}
