$rubric-gap: var(--rubric-tile-gap) !default;

$rubric-tile-horizontal-spacing: var(--spacing-m) !default;
$rubric-tile-height: 84px !default;
$rubric-tile-height-small: 72px !default;
$rubric-tile-height-large: 104px !default;
$rubric-tile-min-width: 320px !default;
$rubric-tile-max-width: $rubric-tile-height * 1.6 !default;
$rubric-tile-border: 2px solid var(--link-color) !default;
$rubric-tile-border-color: var(--background-color) !default;
$rubric-tile-border-color-is-active: var(--link-color) !default;
$rubric-tile-background-color-is-active: $COLOR-WHITE !default;
$rubric-tile-radius: 10px !default;
$rubric-tile-animation: 150ms ease !default;

$rubric-content-spacing: var(--spacing-xxs) !default;
$rubric-content-spacing-large: 16px !default;
$rubric-media-height: 40px !default;
$rubric-media-height-large: 50px !default;
$rubric-media-spacing: -2px !default;
$rubric-headline-spacing: var(--spacing-xxs) !default;
$rubric-link-spacing: var(--spacing-xs) !default;

$rubric-background-inset: 0 0 0 -20% !default;
$rubric-background-inset-desktop: 0 0 0 -35% !default;
$rubric-background-scale: 1.5 !default;
$rubric-background-scale-tablet: .8 !default;
$rubric-background-scale-desktop: .7 !default;
$rubric-background-translate: -27% 18% !default;
$rubric-background-translate-tablet: -27% -12% !default;
$rubric-background-translate-desktop: -28% -17% !default;

:root {
    @include mq($until: mobile) {
        --rubric-tile-width: 100%;
    }

    @include mq($from: mobile, $until: tabletlandscape) {
        --rubric-tile-width: calc(50% - var(--rubric-tile-gap));
    }

    @include mq($from: tabletlandscape) {
        --rubric-tile-width: calc(25% - var(--rubric-tile-gap));
    }

    --rubric-tile-gap: var(--spacing-xxs);
}

.c-rubric {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: $rubric-gap;
    margin: 0 auto;
}

.c-rubric__tile {
    flex: 0 1 var(--rubric-tile-width);
    overflow: hidden;
    transition: $rubric-tile-animation;
    min-height: $rubric-tile-height;
    height: $rubric-tile-height;
    min-width: $rubric-tile-min-width;
    max-width: $rubric-tile-max-width;
    border: $rubric-tile-border;
    border-color: $rubric-tile-border-color;
    border-radius: $rubric-tile-radius;
    cursor: pointer;

    @include mq($until: mobile) {
        margin: 0 $rubric-tile-horizontal-spacing;
        min-width: 100%;
        min-height: $rubric-tile-height-small;
        height: $rubric-tile-height-small;
    }

    @include mq($from: tabletlandscape) {
        min-height: $rubric-tile-height-large;
        height: $rubric-tile-height-large;
    }

    .c-rubric__body {
        visibility: hidden;
    }

    &.is-active {
        height: fit-content;
        border-color: $rubric-tile-border-color-is-active;
        background-color: $rubric-tile-background-color-is-active;

        .c-rubric__body {
            visibility: visible;
        }

        .c-rubric__logo {
            filter: unset;
        }

        .c-rubric__headline {
            @include text-overflow(0);
        }
    }
}

.c-rubric__media {
    height: $rubric-media-height;
    margin: $rubric-media-spacing 0 0 $rubric-media-spacing;
    pointer-events: none;
    user-select: none;

    @include mq($from: tabletlandscape) {
        height: $rubric-media-height-large;
    }
}

.c-rubric__logo {
    width: auto;
    object-fit: contain;
    filter: brightness(0) invert(1);
}

.c-rubric__content {
    padding: $rubric-content-spacing;

    @include mq($from: tabletlandscape) {
        padding-top: $rubric-content-spacing-large;
    }
}

.c-rubric__headline {
    --headline-horizontal-spacing: 0;
    --font-weight: 600;

    @include typo-s;
    @include text-overflow(1);

    @include mq($until: tabletlandscape) {
        margin: 0;
    }
}

.c-rubric__text {
    @include typo-xs;
}

.c-rubric__link {
    --link-text-decoration: none;

    width: fit-content;
    margin: $rubric-link-spacing auto 0 auto;
}

.c-rubric__background {
    @include z-index(background);

    position: absolute;
    height: auto;
    translate: $rubric-background-translate;
    scale: $rubric-background-scale;

    @include mq($from: tabletportrait) {
        translate: $rubric-background-translate-tablet;
        scale: $rubric-background-scale-tablet;
    }

    @include mq($from: desktop) {
        translate: $rubric-background-translate-desktop;
        scale: $rubric-background-scale-desktop;
    }
}
