$highlight-bg-color: $COLOR-YELLOW !default;
$highlight-spacing: $SPACING-M-MOBILE !default;
$highlight-border-radius: 20px !default;

.u-highlight {
    border-radius: $highlight-border-radius !important;
    padding: $highlight-spacing !important;
    background-color: $highlight-bg-color !important;
}

.u-display-block {
    display: block !important;
}

.u-text-center {
    text-align: center !important;
}
