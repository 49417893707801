$content-header-size: $inuit-wrapper-width !default;
$content-header-logo-min-height: 160px !default;
$content-header-logo-height-small: 260px !default;
$content-header-logo-height-large: 300px !default;
$content-header-image-min-height: 180px !default;
$content-header-image-height-small: 300px !default;
$content-header-image-height-large: 500px !default;
$content-header-size: 50% !default;
$content-header-headline-spacing: 105px !default;
$content-header-left-full-width: 1880px !default;
$content-header-left-spacing: var(--spacing-wrapper) !default;
$content-header-left-spacing-small: var(--spacing-m) !default;
$content-header-logo-size-small: 110px !default;
$content-header-logo-size-large: 180px !default;
$content-header-logo-right: var(--spacing-wrapper) !default;
$content-header-logo-right-small: var(--spacing-m) !default;
$content-header-logo-top-small: -8px !default;
$content-header-logo-top-large: -14px !default;

.c-content-header {
    @include mq($from: tabletportrait) {
        display: flex;
        justify-content: end;
        height: $content-header-image-height-small;
    }

    @include mq($from: tabletlandscape) {
        height: $content-header-image-height-large;
    }

    @include mq($from: desktop) {
        margin-right: auto;
        margin-left: auto;
        max-width: $content-header-size;
    }
}

.c-content-header--full-width {
    @include mq($from: tabletportrait) {
        height: $content-header-logo-height-small;
    }

    @include mq($from: tabletlandscape) {
        height: $content-header-logo-height-large;
    }

    .c-content-header__left {
        width: 100%;
        overflow-y: clip;

        @include mq($from: tabletlandscape) {
            width: $content-header-left-full-width;
        }
    }
}

.c-content-header__left,
.c-content-header__right {
    position: relative;

    @include mq($from: tabletportrait) {
        width: $content-header-size;
    }
}

.c-content-header__left {
    position: relative;
    display: grid;
    align-items: end;
    overflow: hidden;
    padding-right: $content-header-left-spacing;
    padding-left: $content-header-left-spacing;

    @include mq($until: mobile) {
        padding: 0 $content-header-left-spacing-small;
    }

    @include mq($from: tabletportrait) {
        min-height: $content-header-image-min-height;
    }
}

.c-content-header__logo {
    position: absolute;
    top: $content-header-logo-top-small;
    left: $content-header-logo-right;
    width: $content-header-logo-size-small;
    object-fit: contain;
    height: auto;

    @include mq($until: mobile) {
        left: $content-header-logo-right-small;
    }

    @include mq($from: tabletportrait) {
        top: $content-header-logo-top-large;
        width: $content-header-logo-size-large;
    }
}

.c-content-header__headline {
    --headline-horizontal-spacing: 0;

    @include mq($until: tabletportrait) {
        padding-top: $content-header-headline-spacing;
    }
}

.c-content-header__image {
    @include mq($from: tabletlandscape) {
        height: $content-header-image-height-large;
    }
}
