$nav-breakpoint: tabletportrait !default;

$nav-toggle-btn-pos-right: 35px !default;
$nav-toggle-btn-pos-right-desktop: 75px !default;
$nav-toggle-btn-pos-right-hover-desktop: calc(
    $nav-toggle-btn-pos-right-desktop + 10px
) !default;
$nav-toggle-btn-pos-right-if-open: 45px !default;
$nav-toggle-btn-pos-right-if-open-desktop: min(20vw, 200px) !default;
$nav-toggle-btn-bg-color: transparent !default;
$nav-toggle-btn-color: $COLOR-WHITE !default;
$nav-toggle-btn-icon-size: 16px !default;
$nav-toggle-btn-icon-size-desktop: 40px !default;
$nav-toggle-btn-pos-from-top: 119px !default;
$nav-toggle-btn-pos-from-top-desktop: 33vh !default;
$nav-toggle-btn-transition: right ease-in-out 0.2s !default;
$nav-toggle-btn-triangle-pos-right: 43px !default;
$nav-toggle-btn-triangle-pos-right-desktop: 73px !default;
$nav-toggle-btn-triangle-pos-right-if-open: $nav-toggle-btn-triangle-pos-right !default;
$nav-toggle-btn-triangle-pos-right-if-open-desktop: 100px !default;
$nav-toggle-btn-triangle-border-width: 35px !default;
$nav-toggle-btn-triangle-border-width-desktop: 60px !default;
$nav-toggle-btn-triangle-border-width-if-open: $nav-toggle-btn-triangle-border-width !default;
$nav-toggle-btn-triangle-border-width-if-open-desktop: 140px !default;
$nav-toggle-btn-triangle-border-radius: 30px !default;
$nav-toggle-btn-triangle-border-radius-if-open: $nav-toggle-btn-triangle-border-radius !default;
$nav-toggle-btn-triangle-border-radius-if-open-desktop: 60px !default;
$nav-toggle-btn-triangle-size: 500px !default;
$nav-toggle-btn-triangle-rotation: rotate(-135deg) !default;
$nav-toggle-btn-triangle-transform-pos: top right !default;
$nav-toggle-btn-color-focus-visible: $COLOR-BLACK !default;
$nav-toggle-btn-triangle-bg-color: $COLOR-YELLOW !default;
$nav-toggle-btn-triangle-bg-color-focus-visible: $COLOR-RED !default;
$nav-toggle-btn-triangle-border-color: $COLOR-RED !default;
$nav-toggle-btn-triangle-border-color-focus-visible: $COLOR-YELLOW !default;
$nav-toggle-btn-triangle-transition: all ease-in-out 0.2s !default;
$nav-toggle-btn-triangle-z-index: -1 !default;

$nav-bounce-animation: 1.5s ease 0s 2 normal forwards !default;
$nav-tooltip-pos-right: 90px !default;
$nav-tooltip-pos-top: -32px !default;
$nav-tooltip-width: 200px !default;
$nav-tooltip-spacing: 20px !default;
$nav-tooltip-triangle-border-width: 15px 0 15px 15px !default;
$nav-tooltip-triangle-border-color: transparent transparent transparent
    $COLOR-RED !default;
$nav-tooltip-triangle-pos-top: 29px !default;
$nav-tooltip-triangle-pos-right: -15px !default;

$nav-slide-transition-duration: 700ms !default;
$nav-slide-spacing-base: var(--spacing-wrapper) !default;
$nav-slide-spacing-wrapper-mobile: $SPACING-LAYOUT-MOBILE !default;

$nav-list-width-mobile: 270px;
$nav-list-animation-desktop-duration: 0.5s !default;
$nav-list-indentation: var(--spacing-xs) !default;
$nav-list-slide-pos-x-from: -30px !default;
$nav-list-slide-pos-x-to: 0 !default;
$nav-list-level-0-spacing-bottom: var(--spacing-s) !default;

$nav-item-first-child-spacing: var(--spacing-s) !default;
$nav-item-last-child-spacing: var(--spacing-xs) !default;
$nav-item-min-height: var(--btn-size) !default;

$nav-link-color-default: $COLOR-BLACK !default;
$nav-link-color-hover: $COLOR-BLACK !default;
$nav-link-min-width: 230px !default;
$nav-link-min-width-mobile: 75% !default;
$nav-link-spacing: $SPACING-XXS-TABLET 0 !default;
$nav-link-color-current: $COLOR-RED !default;
$nav-link-font-weight: 700 !default;

$nav-next-level-btn-rotation-mobile-default: 90deg !default;
$nav-next-level-btn-rotation-mobile: -90deg !default;
$nav-next-level-btn-rotation-desktop: 180deg !default;

$nav-scroll-triangle-placeholder-size: 200px !default;

$nav-buttons-spacing: $SPACING-XXS-DESKTOP !default;
$nav-buttons-spacing-bottom: $SPACING-M-TABLET !default;
$nav-button-width: 200px !default;
$nav-button-hover-border-color: $COLOR-RED !default;

.c-nav {
    @include z-index(navigation-overlay);

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    overflow-x: auto;
    max-height: 100dvh;
}

.c-nav__toggle-btn {
    @include z-index(navigation-toggle-button);

    position: fixed;
    top: $nav-toggle-btn-pos-from-top;
    right: $nav-toggle-btn-pos-right;
    transition: $nav-toggle-btn-transition;
    border: none;
    padding: 0;
    background-color: $nav-toggle-btn-bg-color;
    cursor: pointer;
    color: $nav-toggle-btn-color;

    @include mq($from: $nav-breakpoint) {
        top: $nav-toggle-btn-pos-from-top-desktop;
        right: $nav-toggle-btn-pos-right-desktop;
    }

    &:not(.is-nav-open) {
        .c-nav__icon-close {
            display: none;
        }
    }

    .c-nav__tooltip {
        display: none;
    }

    .c-icon {
        --font-size-button: #{$nav-toggle-btn-icon-size};

        @include mq($from: $nav-breakpoint) {
            --font-size-button: #{$nav-toggle-btn-icon-size-desktop};
        }
    }

    &.is-nav-open {
        right: $nav-toggle-btn-pos-right-if-open;

        .c-nav__icon-open {
            display: none;
        }

        &::before {
            right: $nav-toggle-btn-triangle-pos-right-if-open;
            border-width: $nav-toggle-btn-triangle-border-width-if-open;
            border-top-right-radius: $nav-toggle-btn-triangle-border-radius-if-open;
        }

        @include mq($from: $nav-breakpoint) {
            right: $nav-toggle-btn-pos-right-if-open-desktop;

            &::before {
                right: $nav-toggle-btn-triangle-pos-right-if-open-desktop;
                border-width: $nav-toggle-btn-triangle-border-width-if-open-desktop;
                border-top-right-radius: $nav-toggle-btn-triangle-border-radius-if-open-desktop;
            }
        }
    }

    &.is-nav-bounce {
        @include mq($from: $nav-breakpoint) {
            animation: button-bounce $nav-bounce-animation;

            &::before {
                animation: nav-bounce $nav-bounce-animation;
            }
        }

        .c-nav__tooltip {
            @include mq($from: $nav-breakpoint) {
                position: absolute;
                right: $nav-tooltip-pos-right;
                top: $nav-tooltip-pos-top;
                display: block;
                width: $nav-tooltip-width;
                padding: $nav-tooltip-spacing;

                &::after {
                    content: '';
                    position: absolute;
                    top: $nav-tooltip-triangle-pos-top;
                    right: $nav-tooltip-triangle-pos-right;
                    border-width: $nav-tooltip-triangle-border-width;
                    border-color: $nav-tooltip-triangle-border-color;
                    border-style: solid;
                }
            }
        }
    }

    &::before {
        /* before => red yellow Triangle Settings */
        content: '';
        position: absolute;
        z-index: $nav-toggle-btn-triangle-z-index;
        top: 50%;
        right: $nav-toggle-btn-triangle-pos-right;
        transform: $nav-toggle-btn-triangle-rotation;
        transform-origin: $nav-toggle-btn-triangle-transform-pos;
        transition: $nav-toggle-btn-triangle-transition;
        border:
            $nav-toggle-btn-triangle-border-width solid
            $nav-toggle-btn-triangle-border-color;
        border-top-right-radius: $nav-toggle-btn-triangle-border-radius;
        width: $nav-toggle-btn-triangle-size;
        height: $nav-toggle-btn-triangle-size;
        background-color: $nav-toggle-btn-triangle-bg-color;

        @include mq($from: $nav-breakpoint) {
            right: $nav-toggle-btn-triangle-pos-right-desktop;
            border-width: $nav-toggle-btn-triangle-border-width-desktop;
        }
    }

    &:hover:not(.is-nav-open) {
        @include mq($from: $nav-breakpoint) {
            right: $nav-toggle-btn-pos-right-hover-desktop;
        }
    }

    &:focus-visible {
        color: $nav-toggle-btn-color-focus-visible;

        &::before {
            border-color: $nav-toggle-btn-triangle-border-color-focus-visible;
            background-color: $nav-toggle-btn-triangle-bg-color-focus-visible;
        }
    }
}

.c-nav__container-slide {
    position: relative;
    transform: translateX(0);
    overflow-x: auto;
    transition:
        transform, height $nav-slide-transition-duration, 0s ease-in-out,
        ease-in-out 0s, $nav-slide-transition-duration;
    height: 0;
    padding-right: $nav-slide-spacing-base;
    padding-left: $nav-slide-spacing-base;

    .is-open & {
        height: 100%;
    }

    .c-nav:not(.is-open) & {
        transform: translateX(calc(100%));
    }

    @include mq($until: mobile) {
        padding-left: $nav-slide-spacing-wrapper-mobile;
        padding-right: $nav-slide-spacing-wrapper-mobile;
    }
}

.c-nav__container-content {
    height: 100vh;
}

.c-nav__list {
    @include hidden-list-style;

    margin: 0;
    width: fit-content;
    padding: 0;

    /* Animation above the 2. menu level */
    @include mq($until: $nav-breakpoint) {
        /* Mobile */

        /*
        * [1] above the second menu level
        */
        .is-active {
            /* [1] */
            display: block;
        }

        :where(.c-nav__level-0 &) {
            display: none;
        }

        &.c-nav__level-0 {
            width: $nav-list-width-mobile;
            margin-bottom: $nav-list-level-0-spacing-bottom;
        }
    }

    @include mq($from: $nav-breakpoint) {
        /* Desktop */
        .is-active {
            display: block;
            animation-duration: $nav-list-animation-desktop-duration;
            animation-name: slide-in-desktop;
        }

        :where(.c-nav__level-0 &) {
            position: absolute;
            top: 0;
            left: 100%;
            display: none;
        }
    }

    .c-nav__list {
        flex: 0 1 100%;
        margin-left: $nav-list-indentation;

        @include mq($until: $nav-breakpoint) {
            .c-nav__item {
                &:last-child {
                    margin-bottom: $nav-item-last-child-spacing;
                }
            }
        }
    }
}

.c-nav__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    min-height: $nav-item-min-height;
}

.c-nav__item-link {
    --link-text-decoration: none;
    --link-color: #{$nav-link-color-default};
    --link-color-hover: #{$nav-link-color-hover};

    min-width: $nav-link-min-width-mobile;
    padding: $nav-link-spacing;
    text-transform: uppercase;
    font-weight: bold;

    .is-current-item > &,
    .is-current-item > &[aria-current='current-page'] {
        --link-color: #{$nav-link-color-current};

        font-weight: $nav-link-font-weight;

        &:hover {
            color: var(--link-color);
        }
    }

    .is-last-level & {
        font-weight: normal;
    }

    &:focus {
        --link-background-color: transparent;
    }

    @include mq($from: $nav-breakpoint) {
        min-width: $nav-link-min-width;
    }
}

.c-nav__next-level-toggle {
    @include mq($until: $nav-breakpoint) {
        transform: rotate($nav-next-level-btn-rotation-mobile-default);
    }

    &[aria-expanded='true'] {
        --btn-color: var(--link-color);

        @include mq($until: $nav-breakpoint) {
            transform: rotate($nav-next-level-btn-rotation-mobile);
        }

        @include mq($from: $nav-breakpoint) {
            &:hover,
            &:focus-visible {
                transform: rotate($nav-next-level-btn-rotation-desktop);
            }
        }
    }
}

.c-nav__buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    gap: $nav-buttons-spacing;
    margin: $nav-buttons-spacing 0;
    padding-bottom: $nav-buttons-spacing-bottom;

    .c-btn {
        min-width: $nav-button-width;

        &:hover {
            border-color: $nav-button-hover-border-color;
        }
    }
}

@include mq($from: $nav-breakpoint) {
    /* Desktop */
    .c-nav__container-horizontal-scroll {
        position: relative;
        overflow: auto;
        max-width: calc(100% - $nav-scroll-triangle-placeholder-size);
        height: calc(100% - var(--header-logo-height));
    }

    .c-nav__level-0 {
        position: relative; /* Position above next menu level */
    }

    .c-nav__item {
        flex-wrap: nowrap;
    }
}

@keyframes slide-in-desktop {
    0% {
        opacity: 0;
        transform: translateX($nav-list-slide-pos-x-from);
    }

    30% {
        opacity: 1;
    }

    100% {
        transform: translateX($nav-list-slide-pos-x-to);
    }
}

@keyframes button-bounce {
    0% {
        right: $nav-toggle-btn-pos-right-desktop;
    }

    50% {
        right: $nav-toggle-btn-pos-right-if-open-desktop;
    }

    100% {
        right: $nav-toggle-btn-pos-right-desktop;
    }
}

@keyframes nav-bounce {
    0% {
        right: $nav-toggle-btn-triangle-pos-right-desktop;
        border-width: $nav-toggle-btn-triangle-border-width-desktop;
        border-top-right-radius: $nav-toggle-btn-triangle-border-radius;
    }

    50% {
        right: $nav-toggle-btn-triangle-pos-right-if-open-desktop;
        border-width: $nav-toggle-btn-triangle-border-width-if-open-desktop;
        border-top-right-radius: $nav-toggle-btn-triangle-border-radius-if-open-desktop;
    }

    100% {
        right: $nav-toggle-btn-triangle-pos-right-desktop;
        border-width: $nav-toggle-btn-triangle-border-width-desktop;
        border-top-right-radius: $nav-toggle-btn-triangle-border-radius;
    }
}
