$to-top-bottom-spacing: var(--spacing-m) !default;
$to-top-spacing: var(--spacing-s) !default;
$to-top-spacing-desktop: calc(calc(var(--spacing-wrapper) / 4) + 2px) !default;
$to-top-background-color: $COLOR-WHITE !default;
$to-top-border-color: $COLOR-BLACK !default;
$to-top-icon-rotation: -90deg !default;
$to-top-icon-line-height: 2 !default;

.c-to-top {
    @include z-index(jump-to-top-button);

    visibility: hidden;
    position: fixed;
    bottom: $to-top-bottom-spacing;
    right: 0;
    opacity: 0;
    margin: $to-top-spacing;
    border: 2px solid $to-top-border-color;
    background-color: $to-top-background-color;

    &.is-active {
        visibility: visible;
        opacity: 1;
    }
    
    &:hover {
        scale: 1.25;
        transition-duration: 200ms;
    }

    @include mq($from: tabletlandscape) {
        bottom: 0;
        margin: $to-top-spacing-desktop;
    }
}

.c-to-top__icon {
    rotate: $to-top-icon-rotation;

    .c-icon::before {
        line-height: $to-top-icon-line-height;
    }
}
