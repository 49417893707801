$threesixty-gap: var(--spacing-xs) !default;
$threesixty-padding: var(--spacing-xs) !default;
$threesixty-margin: var(--spacing-s) !default;
$threesixty-aspect-ratio: $RATIO-THREE-TWO !default;

.c-three-sixty {
    display: grid;
    grid-template-rows: auto;
    grid-auto-rows: 1fr;
    gap: $threesixty-gap;

    @include mq($until: tabletportrait) {
        grid-template-columns: 1fr;
    }

    @include mq($from: tabletportrait) {
        grid-template-columns: repeat(2, 1fr);
    }
}

.c-three-sixty__headline {
    grid-column: 1 / -1;
    margin-bottom: 0;
}

.c-three-sixty__link {
    text-decoration-line: none;

    &:hover {
        text-decoration-line: underline;
    }
}

.c-three-sixty__item {
    display: flex;
    height: 100%;
    padding: $threesixty-padding 0;
}

.c-three-sixty__media {
    display: flex;
    align-items: center;
    height: 100%;

    @include mq($until: tabletportrait) {
        flex: 5 5 0;
    }

    @include mq($from: tabletportrait) {
        flex: 1 1 0;
    }
}

.c-three-sixty__image {
    aspect-ratio: $threesixty-aspect-ratio;
    height: auto;
}

.c-three-sixty__content {
    align-self: center;
    margin: 0 $threesixty-margin;

    @include mq($until: tabletportrait) {
        flex: 7 7 0;
    }

    @include mq($from: tabletportrait) {
        flex: 2 2 0;
    }
}

.c-three-sixty__subheader {
    --font-text-transform: uppercase;
    --font-weight: 700;

    @include typo-xxs;
}

.c-three-sixty__header {
    @include mq($until: tabletportrait) {
        @include text-overflow(4);
    }
}
