$rss-item-border: 6px solid $COLOR-YELLOW !default;
$rss-item-spacing: var(--spacing-s) !default;
$rss-title-spacing: var(--spacing-xs) !default;
$rss-color: $COLOR-BLACK !default;

.c-rss {
    @include hidden-list-style;

    background-color: unset;
}

.c-rss__item {
    border-bottom: $rss-item-border;
    padding: $rss-item-spacing 0;

    &:first-child {
        border-top: $rss-item-border;
    }
}

.c-rss__link {
    --link-color: $rss-color;
    --link-color-hover: $rss-color;
    --link-text-decoration: none;

    display: block;
}

.c-rss__title {
    @include typo-l;

    margin-bottom: $rss-title-spacing;

    &:only-child {
        margin: 0;
    }
}
