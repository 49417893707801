$z-index: (
    lightbox: 100,
    navigation-toggle-button: 20,
    navigation-overlay: 10,
    jump-to-top-button: 5,
    banner: 1,
    splide: 1,
    background: -10
);

@function z-index($key) {
    @return map-get($z-index, $key);
}

@mixin z-index($key) {
    z-index: z-index($key);
}