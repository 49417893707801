$result-color: $COLOR-BLACK !default;
$result-color-light: $COLOR-GREY !default;
$result-font-style: italic !default;
$result-spacing: var(--spacing-s) !default;
$result-spacing-large: var(--spacing-m) !default;
$result-line-height: 0.5 !default;

.c-result__number,
.c-result__title a {
    --link-text-decoration: none;
    --link-color: $result-color;

    @include typo-m;

    text-transform: uppercase;
}

.c-result__number,
.c-result__title {
    margin-bottom: 0;
}

.c-result__title {
    line-height: $result-line-height;
}

.c-result__item {
    margin-bottom: $result-spacing-large;
}

.c-result__teaser {
    color: $result-color-light;
}

.c-result__info {
    font-style: $result-font-style;
}

.c-result__count {
    margin: 0 0 $result-spacing 0;
}

.c-result__header {
    display: flex;
    align-items: baseline;
    flex-direction: column;

    @include mq($from: tabletlandscape) {
        flex-direction: row;
    }
}
