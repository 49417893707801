$teaser-slider-content-spacing: $SPACING-S-TABLET !default;
$teaser-slider-content-height: 192px !default;

$teaser-slider-extend-content-height: 120px !default;
$teaser-slider-extend-pagination-bottom: 102px !default;

.c-teaser-slider {
    .splide__arrows {
        top: calc(50% - ($teaser-slider-content-height / 2));
    }

    .c-teaser__content {
        height: $teaser-slider-content-height;
        padding: $teaser-slider-content-spacing $teaser-slider-content-spacing 0;
    }
}

.c-teaser-slider--extend {
    .splide__pagination {
        bottom: $teaser-slider-extend-pagination-bottom;
    }

    .c-teaser__content {
        height: $teaser-slider-extend-content-height;
    }
}
