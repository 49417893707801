$top-news-left-spacing: $SPACING-XXS-TABLET !default;

$top-news-flag-top: $SPACING-XS-TABLET !default;
$top-news-flag-spacing: $SPACING-XXS-MOBILE $SPACING-S-TABLET !default;

$top-news-snippet-inner-spacing: $SPACING-XS-MOBILE 50px $SPACING-XXS-MOBILE !default;
$top-news-snippet-inner-spacing-mobile: $SPACING-XS-MOBILE $SPACING-S-TABLET $SPACING-XXS-MOBILE !default;
$top-news-snippet-spacing: $SPACING-XXS-TABLET !default;
$top-news-snippet-height: 90px !default;

$top-news-content-height: 112px !default;
$top-news-pagination-bottom: 94px !default;
$top-news-pagination-bottom-large: 82px !default;

$splide-buttons-height: 72px !default;

.c-top-news {
    display: flex;
    flex-flow: row wrap;
    list-style: none;
    padding: 0;
}

.c-top-news__item {
    flex: 0 1 auto;
    width: 100%;

    &:first-child {
        margin-bottom: $top-news-left-spacing;
    }
}

.c-top-news__left {
    height: calc(100% - $splide-buttons-height - $top-news-snippet-spacing);

    .splide__arrows {
        top: calc(50% - $top-news-content-height / 2);
    }

    .splide__pagination {
        bottom: $top-news-pagination-bottom;

        @include mq($from: tabletlandscape) {
            bottom: $top-news-pagination-bottom-large;
        }
    }

    .c-teaser__link {
        @include mq($from: tabletlandscape) {
            width: 100%;
        }
    }

    .c-teaser__content {
        height: $top-news-content-height;

        @include mq($from: tabletlandscape) {
            height: 100%;
        }
    }
}

.c-top-news__right {
    @include hidden-list-style;

    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100%;

    .c-teaser {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.c-top-news__flag {
    @include z-index(splide);

    position: absolute;
    top: $top-news-flag-top;
    left: 0;
    display: block;
    padding: $top-news-flag-spacing;
    text-transform: uppercase;
}

.c-top-news__snippet {
    flex-grow: 1;
    margin-bottom: $top-news-snippet-spacing;
    height: $top-news-snippet-height;
    padding: $top-news-snippet-inner-spacing-mobile;
    cursor: pointer;

    @include mq($from: tabletlandscape) {
        padding: $top-news-snippet-inner-spacing;
    }

    &.is-active {
        display: none;
    }
}
