/* Colors */
$COLOR-RED: #F03;
$COLOR-WHITE: #FFF;
$COLOR-BLACK: #000;
$COLOR-YELLOW: #FFE600;
$COLOR-BLUE: #005FFF;
$COLOR-BEIGE: #C3B487;
$COLOR-GREEN-DARK: #0F5F41;
$COLOR-GREEN-LIGHT: #69E6B4;
$COLOR-TRANSPARENT: transparent;
$COLOR-GREY: rgba($COLOR-BLACK, 0.6);
$COLOR-GREY-LIGHT: rgba($COLOR-BLACK, 0.1);
$COLOR-GREY-LIGHTER: #E5E5E5;

$theme-scheme-vars: (
    background-color,
    color,
    focus-visible-color,
    link-color,
    link-color-hover,
    link-background-color,
    btn-color,
    btn-bg-color,
    btn-color-hover,
    btn-bg-color-hover,
    btn-color-focus,
    btn-bg-color-focus,
    btn-color-disabled,
    btn-bg-color-disabled
) !default;

$theme-css-properties: (
    'color',
    'background-color',
) !default;

/* Themes */
$theme-color-schemes: (
    "base": (
        background-color: $COLOR-WHITE,
        color: $COLOR-BLACK,
        focus-visible-color: $COLOR-GREEN-LIGHT,
        link-color: $COLOR-RED,
        link-color-hover: $COLOR-RED,
        link-background-color: rgba($COLOR-BLACK, 0.1),
        btn-color: $COLOR-BLACK,
        btn-bg-color: $COLOR-TRANSPARENT,
        btn-color-hover: $COLOR-WHITE,
        btn-bg-color-hover: $COLOR-BLACK,
        btn-color-focus: $COLOR-WHITE,
        btn-bg-color-focus: $COLOR-BLACK,
        btn-color-disabled: rgba($COLOR-BLACK, 0.5),
        btn-bg-color-disabled: $COLOR-TRANSPARENT
    ),
    "red": (
        background-color: $COLOR-RED,
        color: $COLOR-WHITE,
        focus-visible-color: $COLOR-GREEN-LIGHT,
        link-color: $COLOR-WHITE,
        link-color-hover: $COLOR-WHITE,
        link-background-color: rgba($COLOR-WHITE, 0.3),
        btn-color: $COLOR-WHITE,
        btn-bg-color: $COLOR-RED,
        btn-color-hover: $COLOR-BLACK,
        btn-bg-color-hover: $COLOR-WHITE,
        btn-color-focus: $COLOR-BLACK,
        btn-bg-color-focus: $COLOR-WHITE,
        btn-color-disabled: rgba($COLOR-WHITE, 0.5),
        btn-bg-color-disabled: $COLOR-TRANSPARENT
    ),
    "black": (
        background-color: $COLOR-BLACK,
        color: $COLOR-WHITE,
        focus-visible-color: $COLOR-GREEN-LIGHT,
        link-color: $COLOR-WHITE,
        link-color-hover: $COLOR-WHITE,
        link-background-color: rgba($COLOR-WHITE, 0.3),
        btn-color: $COLOR-WHITE,
        btn-bg-color: $COLOR-BLACK,
        btn-color-hover: $COLOR-BLACK,
        btn-bg-color-hover: $COLOR-WHITE,
        btn-color-focus: $COLOR-BLACK,
        btn-bg-color-focus: $COLOR-WHITE,
        btn-color-disabled: rgba($COLOR-WHITE, 0.5),
        btn-bg-color-disabled: $COLOR-TRANSPARENT
    ),
    "yellow": (
        background-color: $COLOR-YELLOW,
        color: $COLOR-BLACK,
        focus-visible-color: $COLOR-GREEN-LIGHT,
        link-color: $COLOR-BLACK,
        link-color-hover: $COLOR-BLACK,
        link-background-color: rgba($COLOR-BLACK, 0.1),
        btn-color: $COLOR-BLACK,
        btn-bg-color: $COLOR-YELLOW,
        btn-color-hover: $COLOR-WHITE,
        btn-bg-color-hover: $COLOR-BLACK,
        btn-color-focus: $COLOR-WHITE,
        btn-bg-color-focus: $COLOR-BLACK,
        btn-color-disabled: rgba($COLOR-BLACK, 0.5),
        btn-bg-color-disabled: $COLOR-TRANSPARENT
    ),
    "blue": (
        background-color: $COLOR-BLUE,
        color: $COLOR-WHITE,
        focus-visible-color: $COLOR-GREEN-LIGHT,
        link-color: $COLOR-WHITE,
        link-color-hover: $COLOR-WHITE,
        link-background-color: rgba($COLOR-WHITE, 0.3),
        btn-color: $COLOR-WHITE,
        btn-bg-color: $COLOR-BLUE,
        btn-color-hover: $COLOR-BLACK,
        btn-bg-color-hover: $COLOR-WHITE,
        btn-color-focus: $COLOR-BLACK,
        btn-bg-color-focus: $COLOR-WHITE,
        btn-color-disabled: rgba($COLOR-WHITE, 0.5),
        btn-bg-color-disabled: $COLOR-TRANSPARENT
    ),
    "beige": (
        background-color: $COLOR-BEIGE,
        color: $COLOR-BLACK,
        focus-visible-color: $COLOR-GREEN-LIGHT,
        link-color: $COLOR-BLACK,
        link-color-hover: $COLOR-BLACK,
        link-background-color: rgba($COLOR-BLACK, 0.1),
        btn-color: $COLOR-BLACK,
        btn-bg-color: $COLOR-TRANSPARENT,
        btn-color-hover: $COLOR-WHITE,
        btn-bg-color-hover: $COLOR-BLACK,
        btn-color-focus: $COLOR-WHITE,
        btn-bg-color-focus: $COLOR-BLACK,
        btn-color-disabled: rgba($COLOR-BLACK, 0.5),
        btn-bg-color-disabled: $COLOR-TRANSPARENT
    ),
    "green-dark": (
        background-color: $COLOR-GREEN-DARK,
        color: $COLOR-WHITE,
        focus-visible-color: $COLOR-YELLOW,
        link-color: $COLOR-WHITE,
        link-color-hover: $COLOR-WHITE,
        link-background-color: rgba($COLOR-WHITE, 0.3),
        btn-color: $COLOR-WHITE,
        btn-bg-color: $COLOR-GREEN-DARK,
        btn-color-hover: $COLOR-BLACK,
        btn-bg-color-hover: $COLOR-WHITE,
        btn-color-focus: $COLOR-BLACK,
        btn-bg-color-focus: $COLOR-WHITE,
        btn-color-disabled: rgba($COLOR-WHITE, 0.5),
        btn-bg-color-disabled: $COLOR-TRANSPARENT
    ),
    "green-light": (
        background-color: $COLOR-GREEN-LIGHT,
        color: $COLOR-BLACK,
        focus-visible-color: $COLOR-RED,
        link-color: $COLOR-BLACK,
        link-color-hover: $COLOR-BLACK,
        link-background-color: rgba($COLOR-BLACK, 0.1),
        btn-color: $COLOR-BLACK,
        btn-bg-color: $COLOR-GREEN-LIGHT,
        btn-color-hover: $COLOR-WHITE,
        btn-bg-color-hover: $COLOR-BLACK,
        btn-color-focus: $COLOR-WHITE,
        btn-bg-color-focus: $COLOR-BLACK,
        btn-color-disabled: rgba($COLOR-BLACK, 0.5),
        btn-bg-color-disabled: $COLOR-TRANSPARENT
    ),
) !default;
