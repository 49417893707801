$teaser-content-spacing: $SPACING-S-TABLET !default;
$teaser-content-spacing--small: $SPACING-XS-TABLET !default;
$teaser-content-date-spacing: 4px !default;
$teaser-content-font-size: $inuit-font-size-h6-xs !default;
$teaser-spacing: var(--spacing-xxs) !default;
$teaser-icon-size: 40px !default;
$teaser-icon-shadow: 0 0 14px $COLOR-GREY !default;
$teaser-icon-color: $COLOR-WHITE !default;
$teaser-buttons-spacing: $SPACING-S-TABLET !default;
$teaser-aspect-ratio: $RATIO-SIXTEEN-NINE !default;

.c-teaser {
    height: 100%;
}

.c-teaser--video {
    .c-teaser__content {
        @include mq($from: tabletportrait) {
            padding: $teaser-content-spacing $teaser-content-spacing $teaser-content-spacing $teaser-content-spacing--small;
        }
    }
}

.c-teaser--video-stage {
    list-style: none;
    margin: 0;
}

.c-teaser__link {
    --link-text-decoration: none;
    --link-color: $COLOR-BLACK;
    --link-color-hover: $COLOR-BLACK;

    position: relative;
    display: block;
    aspect-ratio: $teaser-aspect-ratio;
}

.c-teaser__content {
    --link-text-decoration: none;

    padding: $teaser-content-spacing;
}

.c-teaser__content--no-spacing {
    padding: 0;
}

.c-teaser__content-headline {
    --headline-horizontal-spacing: 0;
    --font-weight: 400;
    --font-text-transform: uppercase;

    @include typo-xs;
    @include text-overflow;
}

.c-teaser__content-date {
    --font-weight: 700;
    --font-text-transform: uppercase;

    @include typo-xxs;
    @include text-overflow;

    margin-bottom: $teaser-content-date-spacing;
    font-size: $teaser-content-font-size;
}

.c-teaser__content-text {
    @include text-overflow;
}

.c-teaser__content-text--two-lines {
    @include text-overflow(2);
}

.c-teaser__content-text--three-lines {
    @include text-overflow(3);
}

.c-teaser__content-text--six-lines {
    @include text-overflow(6);
}

.c-teaser__blockquote {
    @include typo-xxs;
    @include text-overflow(2);
}

.c-teaser__buttons {
    display: flex;
    margin-top: $teaser-content-spacing;
}

.c-teaser__link--no-aspect-ratio {
    aspect-ratio: unset;
}

.c-teaser__image {
    aspect-ratio: $teaser-aspect-ratio;
}

.c-teaser__media {
    position: relative;
    margin-bottom: $teaser-spacing;
    aspect-ratio: $teaser-aspect-ratio;

    .c-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        translate: -50% -50%;
        font-size: $teaser-icon-size;
        text-shadow: $teaser-icon-shadow;
        color: $teaser-icon-color;
    }
}
