$table-image-column-width: 52px !default;
$table-image-size: 20px !default;

$table-alternative-font-weight: 600 !default;
$table-alternative-bg-color: $COLOR-WHITE !default;
$table-alternative-border-colored: 2px solid $COLOR-RED !default;
$table-alternative-border-solid: 1px solid $COLOR-BLACK !default;

$table-secondary-bg-color-secondary: $COLOR-BEIGE !default;

$table-rte-bg-color: $COLOR-YELLOW !default;
$table-rte-bg-color-odd: $COLOR-GREY-LIGHT !default;
$table-rte-bg-color-even: $COLOR-WHITE !default;
$table-rte-max-width: 850px !default;
$table-rte-spacings: $SPACING-XXS-MOBILE !default;
$table-rte-font-size: $font-size-p-xs !default;

.c-table {
    overflow-x: auto;
}

.c-table__image {
    width: $table-image-column-width;
    padding: 0;
    text-align: center;

    img {
        width: $table-image-size;
        height: $table-image-size;
        object-fit: unset;
    }
}

.c-table--secondary {
    thead {
        background-color: $table-secondary-bg-color-secondary;
    }
}

.c-table--alternative {
    tr:first-child td,
    tr:first-child td p {
        border-bottom: $table-alternative-border-colored;
        background-color: $table-alternative-bg-color;
    }

    tr td:first-child,
    tr td:first-child p {
        border-right: $table-alternative-border-colored;
        font-weight: $table-alternative-font-weight;
    }

    tr:nth-child(odd),
    tr:nth-child(even) {
        background-color: $table-alternative-bg-color;
    }

    tr:last-child td {
        border-bottom: 0;
    }

    td {
        border-bottom: $table-alternative-border-solid;
    }
}

.c-table--rte {
    a {
        --link-color: unset;
        --link-color-hover: unset;
    }

    tr:first-child td,
    tr:first-child td p {
        margin: 0;
        background-color: $table-rte-bg-color;
        font-weight: $table-alternative-font-weight;
        font-size: $table-rte-font-size;
        text-transform: uppercase;
    }

    tr:nth-child(odd) {
        background-color: $table-rte-bg-color-odd;
    }

    tr:nth-child(even) {
        background-color: $table-rte-bg-color-even;
    }
}
