$card-slider-spacing: var(--spacing-m) 0 !default;
$card-slider-spacing-small: var(--spacing-s) !default;
$card-slider-spacing-tiny: var(--spacing-xs) !default;
$card-slider-headline-spacing: 0 0 var(--spacing-s) var(--spacing-wrapper) !default;
$card-slider-item-bg-color: $COLOR-WHITE !default;
$card-slider-card-spacing: var(--spacing-m) var(--spacing-s) !default;
$card-slider-color-hover: var(--link-color) !default;
$card-slider-splide-pagination-spacing: 15px 0 var(--spacing-s) 0 !default;
$card-slider-splide-arrow-offset: calc(50% - 10px) !default;
$card-slider-icon-size: 40px !default;
$card-slider-icon-shadow: 0 0 14px $COLOR-GREY !default;
$card-slider-icon-color: $COLOR-WHITE !default;
$card-slider-media-no-thumb-bg-color: $COLOR-GREY-LIGHTER !default;
$card-slider-media-no-thumb-color: $COLOR-BLACK !default;
$card-slider-aspect-ratio: $RATIO-SIXTEEN-NINE !default;
$card-slider-video-splide-arrow-offset: calc(50% - 45px) !default;

.c-card-slider {
    position: relative;
    padding: $card-slider-spacing;

    @include mq($until: mobile) {
        --spacing-wrapper: var(--spacing-m);
    }

    .splide__arrows {
        top: $card-slider-splide-arrow-offset;
    }

    .splide__arrow--prev {
        @include mq($until: mobile) {
            left: 0;
        }
    }

    .splide__arrow--next {
        @include mq($until: mobile) {
            right: 0;
        }
    }
}

.c-card-slider__headline {
    @include typo-m;

    margin: $card-slider-headline-spacing;
}

.c-card-slider__item {
    background-color: $card-slider-item-bg-color;
}

.c-card-slider__card {
    padding: $card-slider-card-spacing;
}

.c-card-slider__link {
    --link-text-decoration: none;
}

.c-card-slider__media {
    position: relative;
    margin-bottom: $card-slider-spacing-small;
    aspect-ratio: $card-slider-aspect-ratio;

    .c-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        translate: -50% -50%;
        font-size: $card-slider-icon-size;
        text-shadow: $card-slider-icon-shadow;
        color: $card-slider-icon-color;
    }
}

.c-card-slider__media--no-thumb {
    padding: $card-slider-spacing-tiny;
    background: $card-slider-media-no-thumb-bg-color;
    color: $card-slider-media-no-thumb-color;
}

.c-card-slider__roofline {
    @include typo-xxs;
    @include text-overflow(1);

    --font-weight: 700;
    --font-text-transform: uppercase;
}

.c-card-slider__title {
    @include typo-s;
    @include text-overflow(3);

    --font-text-transform: none;
}

.c-card-slider__pagination {
    .splide__pagination {
        position: relative;
        margin: $card-slider-splide-pagination-spacing;
        bottom: unset;
    }
}

.c-card-slider--video {
    .splide__arrows {
        top: $card-slider-video-splide-arrow-offset;
    }
}

.c-card-slider__item--without-bg-color {
    background-color: transparent;
}

.c-card-slider__card--without-spacing {
    padding: 0;
}
