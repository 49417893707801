/**
 * Example usage:
 * <div class="u-hide-from@tabletportrait">
 * <div class="u-hide-until@tabletportrait">
 */

$breakpoint-separator: \@;

@each $bp-name, $bp-value in $mq-breakpoints {
    .u-hide-from#{$breakpoint-separator}#{$bp-name}{
        @include mq($from: $bp-name) {
            display: none !important;
        }
    }

    .u-hide-until#{$breakpoint-separator}#{$bp-name}{
        @include mq($until: $bp-name) {
            display: none !important;
        }
    }
}
