$accordion-gap: var(--spacing-xs) !default;
$accordion-divider: 2px solid $COLOR-BLACK !default;
$accordion-spacing: var(--spacing-xxs) !default;
$accordion-headline-color: $COLOR-RED !default;
$accordion-body-spacing: var(--spacing-xs) !default;
$accordion-arrow-rotate: 90deg !default;

.c-accordion {
    display: flex;
    flex-direction: column;
    gap: $accordion-gap;
}

.c-accordion__item {
    border-bottom: $accordion-divider;
    margin-top: $accordion-spacing;

    &.is-active {
        .c-accordion__arrow {
            rotate: -$accordion-arrow-rotate;
        }

        .c-accordion__body {
            display: block;
            margin-bottom: $accordion-body-spacing;
            height: fit-content;
        }
    }
}

.c-accordion__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 0;
    margin: 0;
    width: 100%;
    padding: 0;
    cursor: pointer;

    &:hover {
        .c-accordion__headline {
            color: $accordion-headline-color;
        }
    }
}

.c-accordion__headline {
    margin: 0;
    padding: $accordion-body-spacing 0;
    text-align: left;
}

.c-accordion__arrow {
    rotate: $accordion-arrow-rotate;
}

.c-accordion__body {
    display: none;
    height: 0;
}
