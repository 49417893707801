$ui-menu-border-radius: 15px !default;
$ui-menu-border-spacing: $SPACING-LAYOUT-TABLET !default;
$ui-menu-bg-color: $COLOR-WHITE !default;
$ui-state-active-bg-color: $COLOR-GREY-LIGHT !default;

.ui-menu {
    margin-right: auto;
    margin-left: auto;
    border-radius: $ui-menu-border-radius;
    width: 1px; // first width till js calculate the width
    padding: $ui-menu-border-spacing;
    background-color: $ui-menu-bg-color;
}

.ui-menu-item {
    @include hidden-list-style;
}

.ui-state-active {
    background-color: $ui-state-active-bg-color;
}
