$footer-nav-spacing: var(--spacing-s) !default;
$footer-nav-horizontal-spacing: var(--spacing-m) !default;
$footer-nav-spacing--mobile: 130px !default;
$footer-nav-item-spacing: var(--spacing-s) !default;
$footer-nav-socials-item-spacing: var(--spacing-xs) !default;

.c-footer-nav {
    @include mq($until: mobile) {
        margin: 0 $footer-nav-horizontal-spacing;
    }
}

.c-footer-nav__list {
    display: flex;
    margin-left: 0;
    margin-top: $footer-nav-spacing;
    margin-bottom: $footer-nav-spacing;

    @include mq($until: mobile) {
        margin-bottom: $footer-nav-spacing--mobile;
    }
}

.c-footer-nav__list--links {
    @include mq($until: tabletportrait) {
        display: block;
    }
}

.c-footer-nav__list--socials {
    --btn-only-icon-border-color: var(--btn-color);

    justify-content: flex-end;

    .c-footer-nav__item {
        margin-right: $footer-nav-socials-item-spacing;
    }
}

.c-footer-nav__item {
    @include hidden-list-style;

    margin-right: $footer-nav-item-spacing;

    &:last-child {
        margin-right: 0;
    }
}

.c-footer-nav__link {
    text-transform: uppercase;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}
