$nav-header-list-spacing: var(--spacing-xs) !default;
$nav-header-list-spacing-large: var(--spacing-s) !default;
$nav-header-link-color: $COLOR-GREY !default;
$nav-header-link-color-hover: $COLOR-BLACK !default;
$nav-header-link-color-current-page: $COLOR-BLACK !default;
$nav-header-link-spacing-horizontal: var(--spacing-xxs) !default;

#mm-nav {
    display: none;
}

/*
[1] https://gerardkcohen.me/writing/2017/voiceover-list-style-type.html
*/
.c-nav-header {
    flex-grow: 1;

    @media (width <= 1650px) {
        display: none;
    }
}

.c-nav-header__list {
    @include hidden-list-style;

    display: flex;
    flex-wrap: nowrap;
    gap: $nav-header-list-spacing;
    margin: 0 $nav-header-list-spacing;
    padding: 0;

    @include mq($from: tabletlandscape) {
        margin: 0 $nav-header-list-spacing-large;
    }
}

.c-nav-header__link {
    @include typo-xxs;

    display: block;
    margin: 0;
    padding: $nav-header-link-spacing-horizontal;
    text-decoration: none;
    text-transform: uppercase;
    color: $nav-header-link-color;

    &:hover, &:active {
        text-shadow: 0 0 1px $nav-header-link-color-hover;
        color: $nav-header-link-color-hover;
    }

    &:focus-visible {
        text-decoration: underline;
        color: $nav-header-link-color-hover;
    }

    &[aria-current="current-page"] {
        color: $nav-header-link-color-current-page;
        text-shadow: 0 0 1px $nav-header-link-color-hover;
    }
}
