$athlete-content-color: $COLOR-BLACK !default;
$athlete-content-background-color: $COLOR-GREY-LIGHT !default;
$athlete-content-spacing: $SPACING-XS-TABLET !default;
$athlete-content-color: $COLOR-BLACK !default;
$athlete-content-background-color: $COLOR-GREY-LIGHT !default;
$athlete-content-spacing: $SPACING-XS-TABLET $SPACING-S-TABLET !default;
$athlete-content-height: 176px !default;
$athlete-content-height-large: 224px !default;
$athlete-name-font-weight: 300 !default;
$athlete-name-spacing: var(--spacing-m) !default;
$athlete-info-spacing: var(--spacing-xs) !default;

.c-athlete {
    .splide__arrows {
        top: calc(50% - $athlete-content-height / 2);

        @include mq($from: mobile) {
            top: calc(50% - $athlete-content-height-large / 2);
        }
    }

    .splide__pagination {
        position: unset;
    }
}

.c-athlete__link {
    --link-text-decoration: none;

}

.c-athlete__card {
    --link-text-decoration: none;

    display: flex;
    flex-direction: column;
}

.c-athlete__content {
    display: flex;
    flex-direction: column;
    height: $athlete-content-height;
    padding: $athlete-content-spacing;
    background-color: $athlete-content-background-color;
    text-decoration: none;
    color: $athlete-content-color;

    @include mq($from: mobile) {
        height: $athlete-content-height-large;
    }
}

.c-athlete__name {
    @include typo-l;
    @include text-overflow(3);

    margin-bottom: $athlete-name-spacing;
}

.c-athlete__info {
    @include typo-xxs;
    @include text-overflow(2);

    --font-text-transform: uppercase;
    --font-weight: 600;

    margin-bottom: $athlete-info-spacing;
}
