$retrospective-spacing: var(--spacing-m) !default;
$retrospective-border-spacing: var(--spacing-s) !default;
$retrospective-border: 4px solid $COLOR-RED !default;

.c-retrospective {
    margin: 0;
}

.c-retrospective__item {
    @include hidden-list-style;
}

.c-retrospective--border {
    border-left: $retrospective-border;
    padding: 0 $retrospective-border-spacing;
}
