.u-mb0 {
    margin-bottom: 0 !important;
}

.u-mb {
    margin-bottom: var(--spacing-m) !important;
}

.u-mb-s {
    margin-bottom: var(--spacing-xxs) !important;
}

.u-mb-layout {
    margin-bottom: var(--spacing-layout) !important;
}

.u-pv {
    padding: var(--spacing-m) 0 !important;
}

.u-pv-s {
    padding: var(--spacing-xxs) 0 !important;
}
