:root {
    @include mq($until: mobile) {
        --spacing-xl: #{$SPACING-XL-MOBILE};
        --spacing-l: #{$SPACING-L-MOBILE};
        --spacing-m: #{$SPACING-M-MOBILE};
        --spacing-s: #{$SPACING-S-MOBILE};
        --spacing-xs: #{$SPACING-XS-MOBILE};
        --spacing-xxs: #{$SPACING-XXS-MOBILE};
        --spacing-wrapper: #{$SPACING-WRAPPER-MOBILE};
        --spacing-layout: #{$SPACING-LAYOUT-MOBILE};
    }

    @include mq($from: mobile ,$until: tabletlandscape) {
        --spacing-xl: #{$SPACING-XL-TABLET};
        --spacing-l: #{$SPACING-L-TABLET};
        --spacing-m: #{$SPACING-M-TABLET};
        --spacing-s: #{$SPACING-S-TABLET};
        --spacing-xs: #{$SPACING-XS-TABLET};
        --spacing-xxs: #{$SPACING-XXS-TABLET};
        --spacing-wrapper: #{$SPACING-WRAPPER-TABLET};
        --spacing-layout: #{$SPACING-LAYOUT-TABLET};
    }

    @include mq($from: tabletlandscape) {
        --spacing-xl: #{$SPACING-XL-DESKTOP};
        --spacing-l: #{$SPACING-L-DESKTOP};
        --spacing-m: #{$SPACING-M-DESKTOP};
        --spacing-s: #{$SPACING-S-DESKTOP};
        --spacing-xs: #{$SPACING-XS-DESKTOP};
        --spacing-xxs: #{$SPACING-XXS-DESKTOP};
        --spacing-wrapper: #{$SPACING-WRAPPER-DESKTOP};
        --spacing-layout: #{$SPACING-LAYOUT-DESKTOP};
    }
}
