$table-spacing: $SPACING-XXS-TABLET !default;
$table-text-align: start !default;
$table-font-weight: 600 !default;
$table-font-size: $font-size-p-xs !default;
$table-head-bg-color: $COLOR-YELLOW !default;
$table-bg-color: $COLOR-GREY-LIGHT !default;
$table-link-color: $COLOR-BLACK !default;

table {
    thead {
        margin: 0;
        background-color: $table-head-bg-color;
        font-size: $table-font-size;
        text-transform: uppercase;
    }

    tr:nth-child(even) {
        background-color: $table-bg-color;
    }

    th, td {
        padding: $table-spacing calc($table-spacing * 2);
    }

    th {
        font-weight: $table-font-weight;
        text-align: $table-text-align;
    }

    a {
        --link-text-decoration: none;
        --link-color: $table-link-color;
        --link-color-hover: $table-link-color;
    }
}
