$splide-arrow-size: 24px !default;
$splide-arrow-spacing: $SPACING-S-MOBILE !default;
$splide-arrow-fill: var(--splide-icon-color, $COLOR-WHITE) !default;
$splide-arrow-filter: drop-shadow(0 0 3px rgba(0, 0, 0, 100%)) !default;
$splide-arrow-transition: -20px !default;

$splide-pagination-bottom: 174px !default;
$splide-pagination-page-spacing: 2px !default;
$splide-pagination-page-size: 6px !default;
$splide-pagination-page-bg-color: #DDD !default;
$splide-pagination-page-active-bg-color: #888 !default;

$splide-buttons-spacing: $SPACING-S-TABLET !default;

$splide-banner-label-bg-color: $COLOR-WHITE !default;
$splide-banner-label-font-size: $inuit-font-size-h6-xs !default;
$splide-banner-label-font-size-large: $inuit-font-size-h6 !default;
$splide-banner-label-color: $COLOR-BLACK !default;
$splide-banner-label-position-top: -26px !default;
$splide-banner-label-position-bottom: -28px !default;
$splide-banner-label-spacing: 4px !default;

:root {
    --splide-icon-color:  #{$COLOR-WHITE};
}

.splide {
    .is-focus-in {
        .splide__arrow,
        .splide__pagination__page {
            &:focus {
                outline-color: var(--focus-visible-color);
            }
        }
    }

    .is-hidden {
        display: none;
    }
}

.splide--sticky {
    @include z-index(splide);

    position: fixed;
    bottom: 0;
    margin: 0;
}

.splide__arrows {
    @include z-index(splide);

    position: absolute;
    top: 50%;
    display: flex;
    justify-content: space-between;
    translate: 0 -50%;
    width: 100%;
    height: $splide-arrow-size;
}

.splide__arrow {
    @include focus-visible;

    display: flex;
    align-items: center;
    border: none;
    padding: $splide-arrow-spacing;
    background: none;
    cursor: pointer;

    &.splide__arrow--prev {
        transform: scaleX(-1);
        translate: 0 $splide-arrow-transition;

        @include mq($from: tabletlandscape) {
            left: 2%;
        }
    }

    .c-icon {
        width: $splide-arrow-size;
        height: $splide-arrow-size;
        font-size: $splide-arrow-size;
        color: $splide-arrow-fill;
        filter: $splide-arrow-filter;
    }

    &:hover .c-icon {
        opacity: 1;
    }
}

.splide__pagination {
    position: absolute;
    bottom: $splide-pagination-bottom;
    align-items: flex-start;
    width: 100%;
}

.splide__pagination__page {
    margin: $splide-pagination-page-spacing;
    border: 0;
    border-radius: 50%;
    height: $splide-pagination-page-size;
    width: $splide-pagination-page-size;
    padding: 0;
    background-color: $splide-pagination-page-bg-color;

    &.is-active {
        background-color: $splide-pagination-page-active-bg-color;
    }
}

.splide__buttons {
    display: flex;
    padding: 0 $splide-buttons-spacing $splide-buttons-spacing;

    .c-btn:not(:last-child) {
        margin-right: $splide-buttons-spacing;
    }
}

.splide__banner-label {
    --font-text-transform: uppercase;

    @include typo-xxs;
    @include z-index(banner);

    background-color: $splide-banner-label-bg-color;
    padding: $splide-banner-label-spacing;
    position: absolute;
    top: $splide-banner-label-position-top;
    right: 0;
    margin: 0;
    font-size: $splide-banner-label-font-size;
    color: $splide-banner-label-color;

    &:empty {
        display: none;
    }

    @include mq($from: tabletportrait) {
        --font-weight: bold;

        top: auto;
        bottom: $splide-banner-label-position-bottom;
        right: 0;
        font-size: $splide-banner-label-font-size-large;
    }
}
