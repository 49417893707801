/* Typography */

.u-typo-xxl {
    @include typo-xxl;
}

.u-typo-xl {
    @include typo-xl;
}

.u-typo-l {
    @include typo-l;
}

.u-typo-m {
    @include typo-m;
}

.u-typo-s {
    @include typo-s;
}

.u-typo-xs {
    @include typo-xs;
}

.u-typo-xxs {
    @include typo-xxs;
}
