@use 'sass:math';

$lightbox-background-color: rgba(0, 0, 0, 80%) !default;

$lightbox-content-max-width: 422px !default;
$lightbox-content-max-width-large: 800px !default;

$lightbox-body-height: 212px !default;

$lightbox-controls-spacing: 0 $SPACING-S-TABLET $SPACING-S-TABLET !default;
$lightbox-aspect-ratio: $RATIO-SIXTEEN-NINE !default;

.c-lightbox {
    @include z-index(lightbox);

    position: fixed;
    inset: 0;
    display: none;
    overflow: hidden;
    width: 100%;
    height: 100vh;
    background-color: $lightbox-background-color;

    .c-lightbox__arrows {
        top: calc(50% - #{math.div($lightbox-body-height, 2)});
    }

    &.is-active {
        display: grid;
        place-content: center;
    }
}

.c-lightbox__content {
    position: relative;
    overflow-y: auto;
    width: 100%;
    max-width: $lightbox-content-max-width;

    @include mq($from: mobile) {
        max-width: $lightbox-content-max-width;
    }

    @include mq($from: tabletportrait) {
        max-width: $lightbox-content-max-width-large;
    }

    .splide:not(.is-overflow) .splide__pagination {
        display: flex;
    }

    .splide__pagination {
        top: unset;
        bottom: $lightbox-body-height - 16px;
    }
}

.c-lightbox__list,
.c-lightbox__item {
    max-width: 100vw;
}

.c-lightbox__media {
    position: relative;
}

.c-lightbox__image {
    aspect-ratio: $lightbox-aspect-ratio;
}

.c-lightbox__body {
    height: $lightbox-body-height;
}

.c-lightbox__photographer {
    --headline-horizontal-spacing: 0;

    font-weight: 600;
}

.c-lightbox__description {
    @include text-overflow(3);
}

.c-lightbox__content-header {
    display: flex;
    justify-content: flex-end;

    .c-btn {
        --btn-bg-color: transparent;
    }
}

.c-lightbox__controls {
    padding: $lightbox-controls-spacing;
}
